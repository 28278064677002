/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.rules-modal-container {
  padding: 0 15px 10px 15px; }
  .rules-modal-container .rules-title {
    margin-bottom: 20px; }
  .rules-modal-container .rules-subtitle {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    margin: 10px 0;
    border-bottom: 1px solid #EBEBEB; }
  .rules-modal-container .one-off-fields, .rules-modal-container .recurring-fields {
    margin-bottom: 20px; }
  .rules-modal-container .rules-button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .rules-modal-container .rules-button-container .rules-close {
      background: #ffffff;
      color: #333333;
      border: 1px solid #333333; }
    .rules-modal-container .rules-button-container .rules-close, .rules-modal-container .rules-button-container .rules-done {
      padding: 10px 60px;
      font-weight: bold;
      font-size: 14px; }
  .rules-modal-container .disabled {
    cursor: not-allowed;
    pointer-events: none; }
  .rules-modal-container .alert-disabled-rule {
    color: #D4504B; }
  .rules-modal-container .building-rule-alert {
    text-align: center;
    padding: 5px;
    margin-bottom: 20px;
    background: #FFE5E6;
    border: 0.1px solid #FFE5E6;
    border-radius: 4px;
    color: #D4504B;
    font-size: 95%; }
  .rules-modal-container .document-view-block-title {
    font-size: 18px;
    padding: 5px;
    color: #ffffff; }
  .rules-modal-container .building-rule-history-list {
    max-height: 215px;
    overflow-y: scroll;
    padding: 5px 10px;
    border-radius: 0 0 5px 5px; }
  .rules-modal-container .building-rule-history-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 15px;
    padding: 4px 0; }
    .rules-modal-container .building-rule-history-item:before {
      content: '';
      -ms-flex-negative: 0;
          flex-shrink: 0;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      width: 10px;
      height: 10px;
      background: #4FCBB2;
      display: block;
      border-radius: 50%;
      margin: 8px 10px 0 0; }
    .rules-modal-container .building-rule-history-item .building-rule-history-item-date {
      min-width: 80px;
      padding-right: 8px; }

.rules-inactive {
  display: none !important; }

.rules-active {
  margin-bottom: 5px; }
