/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dropzone__container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 25px;
  border-width: 3px;
  border-radius: 3px;
  border-color: #ddd;
  border-style: dashed;
  background-color: #F2F5F7;
  color: #333333;
  outline: none;
  -webkit-transition: border .24s ease-in-out;
  transition: border .24s ease-in-out;
  width: 100%;
  margin-bottom: 16px;
  margin-bottom: 1rem; }
  .dropzone__container p {
    margin-bottom: 0; }
  .dropzone__container a {
    color: #4A90E2;
    cursor: pointer; }

.dropzone__errors {
  color: #DC3545;
  border: #DC3545;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  padding: 8px 16px;
  padding: .5rem 1rem;
  border-radius: .2rem; }
  .dropzone__errors ol {
    display: block;
    list-style-type: decimal;
    -webkit-margin-before: 8px;
            margin-block-start: 8px;
    -webkit-margin-before: .5rem;
            margin-block-start: .5rem;
    -webkit-margin-after: 8px;
            margin-block-end: 8px;
    -webkit-margin-after: .5rem;
            margin-block-end: .5rem;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    -webkit-padding-start: 16px;
            padding-inline-start: 16px;
    -webkit-padding-start: 1rem;
            padding-inline-start: 1rem; }
  .dropzone__errors ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 8px;
            margin-block-start: 8px;
    -webkit-margin-before: .5rem;
            margin-block-start: .5rem;
    -webkit-margin-after: 8px;
            margin-block-end: 8px;
    -webkit-margin-after: .5rem;
            margin-block-end: .5rem;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    -webkit-padding-start: 16px;
            padding-inline-start: 16px;
    -webkit-padding-start: 1rem;
            padding-inline-start: 1rem; }
  .dropzone__errors li {
    display: list-item;
    text-align: -webkit-match-parent; }
