/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.card.my-properties-card-item-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #D8E5EE;
  border-radius: .25rem;
  margin-top: 20px; }
  .card.my-properties-card-item-container .card-header {
    padding: 12px 20px;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #EAEDF0;
    border-bottom: 1px solid #D8E5EE; }
  .card.my-properties-card-item-container .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card.my-properties-card-item-container .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 20px;
    padding: 1.25rem; }
    .card.my-properties-card-item-container .card-body .my-properties-card-item-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      margin: 0 0 10px;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start;
      grid-template-areas: "a b b b"; }
      .card.my-properties-card-item-container .card-body .my-properties-card-item-row .heading {
        grid-area: a; }
      .card.my-properties-card-item-container .card-body .my-properties-card-item-row .text {
        grid-area: b; }
