/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dms-list-view-item {
  position: relative;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-gap: 0px 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #ffffff;
  border: #ffffff 1px solid;
  padding: 10px;
  margin: 0 0 10px; }
  @media (max-width: 499px) {
    .dms-list-view-item {
      padding: 5px;
      grid-gap: 5px; } }
  .dms-list-view-item.red-border {
    border-color: #e8a09d; }
  .dms-list-view-item.bg-light-red {
    background-color: #ffe8e7; }
  .dms-list-view-item.unseen {
    font-weight: bold; }

.dms-list-view-item-col {
  font-size: 14px; }
  .dms-list-view-item-col.file-selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .dms-list-view-item-col.file-selected .file-type-icon {
      margin: 0 0 0 15px; }
  .dms-list-view-item-col.doc-select-all {
    grid-column: 1 / span 3; }
  .dms-list-view-item-col.doc-sp {
    grid-column: 4 / span 3;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dms-list-view-item-col.doc-type {
    grid-column: 7 / span 3;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize; }
  .dms-list-view-item-col.doc-name {
    grid-column: 10 / span 9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .dms-list-view-item-col.doc-name .icon-warning-red {
      margin: 0 10px;
      line-height: 0; }
    .dms-list-view-item-col.doc-name a {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .dms-list-view-item-col.doc-uploaded-date {
    grid-column: 19 / span 3;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .dms-list-view-item-col.doc-actions, .dms-list-view-item-col.invoice-actions {
    grid-column: 22 / span 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    font-weight: normal; }
    .dms-list-view-item-col.doc-actions .dropdown-wrapper .label, .dms-list-view-item-col.invoice-actions .dropdown-wrapper .label {
      padding: 0;
      width: 24px;
      height: 24px;
      font-size: 20px;
      padding-bottom: 11.5px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .dms-list-view-item-col.doc-actions a.schedule-date,
    .dms-list-view-item-col.doc-actions a.job-link, .dms-list-view-item-col.invoice-actions a.schedule-date,
    .dms-list-view-item-col.invoice-actions a.job-link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-right: 1.6px;
      margin-right: .1rem;
      background-color: white;
      border: #f4f4f4 1px solid;
      border-radius: 50%;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 24px;
      height: 24px; }
      .dms-list-view-item-col.doc-actions a.schedule-date span,
      .dms-list-view-item-col.doc-actions a.job-link span, .dms-list-view-item-col.invoice-actions a.schedule-date span,
      .dms-list-view-item-col.invoice-actions a.job-link span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .dms-list-view-item-col.doc-actions a.schedule-date .icon:before,
      .dms-list-view-item-col.doc-actions a.job-link .icon:before, .dms-list-view-item-col.invoice-actions a.schedule-date .icon:before,
      .dms-list-view-item-col.invoice-actions a.job-link .icon:before {
        width: 13px;
        height: 13px; }
  .dms-list-view-item-col.invoice-select-all {
    grid-column: 1 / span 2; }
  .dms-list-view-item-col.invoice-sp {
    grid-column: 3 / span 2;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .dms-list-view-item-col.invoice-contractor-name {
    grid-column: 5 / span 4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .dms-list-view-item-col.invoice-contractor-name .stop-icon {
      margin-left: 5px; }
  .dms-list-view-item-col.invoice-dms-status-container {
    grid-column: 9 / span 3; }
    .dms-list-view-item-col.invoice-dms-status-container .invoice-status {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
  .dms-list-view-item-col.invoice-no {
    grid-column: 12 / span 2;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .dms-list-view-item-col.invoice-no .duplicate-icon {
      cursor: pointer;
      margin-right: 4.8px;
      margin-right: .3rem; }
  .dms-list-view-item-col.invoice-amount {
    grid-column: 14 / span 2;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .dms-list-view-item-col.invoice-added-date {
    grid-column: 16 / span 2;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .dms-list-view-item-col.invoice-sort-currently-with {
    grid-column: 18 / span 2; }
  .dms-list-view-item-col.invoice-currently-with {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    grid-column: 18 / span 2; }
  .dms-list-view-item-col.time-with-item {
    grid-column: 20 / span 2; }
  .dms-list-view-item-col.invoice-paid-date {
    grid-column: 22 / span 2; }
  .dms-list-view-item-col.invoice-actions {
    grid-column: 24 / span 2; }
  .dms-list-view-item-col .dropdown {
    width: 200px;
    font-size: 14px; }
    .dms-list-view-item-col .dropdown .cancel-invoice > a,
    .dms-list-view-item-col .dropdown .reject-invoice > a,
    .dms-list-view-item-col .dropdown .delete-invoice > a {
      color: #D4504B; }
  .dms-list-view-item-col.funding-status {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    grid-column: 18 / span 2; }
  .dms-list-view-item-col.settlement-date {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    grid-column: 20 / span 2; }

.invoice-dms-status-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .invoice-dms-status-container .invoice-status {
    color: #333333;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .invoice-dms-status-container .invoice-status.under-review {
      border-color: #4A90E2;
      color: #4A90E2; }
    .invoice-dms-status-container .invoice-status.processing, .invoice-dms-status-container .invoice-status.extracting {
      color: #F5BF65; }
    .invoice-dms-status-container .invoice-status.strata-manager-approval,
    .invoice-dms-status-container .invoice-status .external-approval {
      border-color: purple;
      color: purple; }
    .invoice-dms-status-container .invoice-status.on-hold {
      border-color: #e0730d;
      color: #e0730d; }
    .invoice-dms-status-container .invoice-status.approved,
    .invoice-dms-status-container .invoice-status .paid {
      border-color: #4FCBB2;
      color: #4FCBB2; }
    .invoice-dms-status-container .invoice-status.cancelled, .invoice-dms-status-container .invoice-status.failed, .invoice-dms-status-container .invoice-status.processing-failed {
      border-color: #D4504B;
      color: #D4504B; }
    .invoice-dms-status-container .invoice-status.deleted {
      border-color: #B7B7B7;
      color: #B7B7B7; }

.funding-status--created {
  padding: 3.2px 8px;
  padding: .2rem .5rem;
  border-radius: .2rem;
  background-color: #F5BF65;
  color: white; }

.funding-status--active {
  padding: 3.2px 8px;
  padding: .2rem .5rem;
  border-radius: .2rem;
  background-color: #4A90E2;
  color: white; }

.funding-status--completed {
  padding: 3.2px 8px;
  padding: .2rem .5rem;
  border-radius: .2rem;
  background-color: #4FCBB2;
  color: white; }

.funding-status--indefault {
  padding: 3.2px 8px;
  padding: .2rem .5rem;
  border-radius: .2rem;
  background-color: #D4504B;
  color: white; }

.funding-status--requesting {
  padding: 3.2px 8px;
  padding: .2rem .5rem;
  border-radius: .2rem;
  background-color: #E0E0E0;
  color: white; }

.document-table__data {
  position: relative;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #ffffff;
  border: #ffffff 1px solid;
  padding: 10px;
  margin: 0 0 10px;
  font-size: 14px; }
  @media (max-width: 499px) {
    .document-table__data {
      padding: 5px;
      grid-gap: 5px; } }
  .document-table__data.unseen {
    font-weight: bold; }
  .document-table__data .file-selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .document-table__data .file-selected .file-type-icon {
      margin: 0 0 0 15px; }

.document-table__data__select-checkbox {
  grid-column: 1 / span 2; }

.document-table__data__sp-number {
  grid-column: 3 / span 2; }

.document-table__data__invoice-status {
  grid-column: 5 / span 2; }

.document-table__data__invoice-number {
  grid-column: 7 / span 3; }

.document-table__data__invoice-amount {
  grid-column: 10 / span 2; }

.document-table__data__invoice-date {
  grid-column: 12 / span 3; }

.document-table__data__funding-status {
  grid-column: 15 / span 3; }

.document-table__data__settlement-date {
  grid-column: 18 / span 3; }

.document-table__data__invoice-paid-date {
  grid-column: 21 / span 3; }

.document-table__data__action-buttons {
  grid-column: 24 / span 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-weight: normal; }
  .document-table__data__action-buttons .dropdown-wrapper .label {
    padding: 0;
    width: 24px;
    height: 24px;
    font-size: 20px;
    padding-bottom: 11.5px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .document-table__data__action-buttons a.schedule-date,
  .document-table__data__action-buttons a.job-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 1.6px;
    margin-right: .1rem;
    background-color: white;
    border: #f4f4f4 1px solid;
    border-radius: 50%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 24px;
    height: 24px; }
    .document-table__data__action-buttons a.schedule-date span,
    .document-table__data__action-buttons a.job-link span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .document-table__data__action-buttons a.schedule-date .icon:before,
    .document-table__data__action-buttons a.job-link .icon:before {
      width: 13px;
      height: 13px; }
