/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.button-container > .download-btn {
  background-color: #4A90E2; }

.button-container > p {
  text-align: center;
  color: #D4504B; }

p.error {
  color: #D4504B; }

.fields.financial-year-period-fields {
  margin-top: 10px; }

label[for="financialYearPeriod"] span.color-blue {
  color: #4A90E2; }
