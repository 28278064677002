/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
footer {
  background: #000000;
  color: #ffffff;
  width: 100%; }
  @media (max-width: 499px) {
    footer.messages-content {
      display: none; } }
  footer .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px !important; }
    @media (max-width: 499px) {
      footer .wrapper {
        text-align: center; } }
    @media (max-width: 399px) {
      footer .wrapper {
        padding: 15px !important; } }
    footer .wrapper .logo {
      width: 100px;
      height: 20px; }
      @media (max-width: 499px) {
        footer .wrapper .logo {
          margin: 15px auto; } }
  footer .footer-sitemap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: auto;
    font-size: 14px; }
    @media (max-width: 399px) {
      footer .footer-sitemap {
        width: 100%; } }
    footer .footer-sitemap .footer-sitemap-legal {
      padding-right: 20px; }
      @media (max-width: 399px) {
        footer .footer-sitemap .footer-sitemap-legal {
          font-size: 12px;
          padding-right: 10px; } }
    footer .footer-sitemap a {
      text-decoration: underline;
      color: inherit; }
    footer .footer-sitemap .social-links {
      margin: 0 auto; }
  footer .social-links-nav .icon::before {
    width: 23px;
    height: 23px;
    background-color: #ffffff; }
