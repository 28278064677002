/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.tippy-popper #invoice-status-explanation-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 90%; }

.tippy-popper #invoice-status-explanation-table tr td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left; }

.tippy-popper #invoice-status-explanation-table tr td:first-child {
  text-align: left;
  vertical-align: middle;
  padding-left: 0; }

.tippy-popper #invoice-status-explanation-table tr:last-child td {
  border-bottom: none; }

.tippy-popper #invoice-status-explanation-table .invoice-status {
  color: #333333; }
  .tippy-popper #invoice-status-explanation-table .invoice-status.under-review {
    border-color: #4A90E2;
    color: #4A90E2; }
  .tippy-popper #invoice-status-explanation-table .invoice-status.processing, .tippy-popper #invoice-status-explanation-table .invoice-status.extracting {
    color: #F5BF65; }
  .tippy-popper #invoice-status-explanation-table .invoice-status.strata-manager-approval,
  .tippy-popper #invoice-status-explanation-table .invoice-status .external-approval {
    border-color: purple;
    color: purple; }
  .tippy-popper #invoice-status-explanation-table .invoice-status.on-hold {
    border-color: #e0730d;
    color: #e0730d; }
  .tippy-popper #invoice-status-explanation-table .invoice-status.approved,
  .tippy-popper #invoice-status-explanation-table .invoice-status .paid {
    border-color: #4FCBB2;
    color: #4FCBB2; }
  .tippy-popper #invoice-status-explanation-table .invoice-status.cancelled, .tippy-popper #invoice-status-explanation-table .invoice-status.failed, .tippy-popper #invoice-status-explanation-table .invoice-status.processing-failed {
    border-color: #D4504B;
    color: #D4504B; }
  .tippy-popper #invoice-status-explanation-table .invoice-status.deleted {
    border-color: #B7B7B7;
    color: #B7B7B7; }
