/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.compare-connect-alert {
  background: #4FCBB2;
  border-bottom: 1.5px solid black; }
  .compare-connect-alert .compare-connect-alert-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background: #4FCBB2;
    width: 100%;
    padding: 0 40px !important;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 50px;
    z-index: 1; }
    @media (max-width: 768px) {
      .compare-connect-alert .compare-connect-alert-container {
        padding: 0 10px !important; } }
  .compare-connect-alert .wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 18px 30px;
    max-width: 1400px; }
  .compare-connect-alert .user-wrapper {
    margin: auto;
    max-width: 1400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 499px) {
      .compare-connect-alert .user-wrapper {
        padding: 18px 20px; } }
    @media (max-width: 1259px) {
      .compare-connect-alert .user-wrapper {
        padding: 0;
        display: block;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .compare-connect-alert .mw-100 {
    max-width: 100%; }
