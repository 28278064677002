/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dropdown-button-container {
  position: relative;
  width: 100%; }
  .dropdown-button-container .more-button {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    color: #fff;
    background: transparent;
    border: none;
    font-size: 20px;
    padding: 0px 10px; }
  .dropdown-button-container .dropdown-container {
    position: absolute;
    top: calc(100% + 5px);
    right: 0px;
    z-index: 9;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 0px; }
    .dropdown-button-container .dropdown-container button {
      background-color: #fff;
      border: none;
      padding: 10px 25px;
      white-space: nowrap;
      text-align: left; }
      .dropdown-button-container .dropdown-container button:hover {
        text-decoration: underline; }
