/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.document-results {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 5px;
  padding: 8px 20px;
  font-size: 14px; }
  .document-results .document-number-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #A5A5A5; }
    .document-results .document-number-date .document-number {
      margin-right: 10px; }
  .document-results li {
    cursor: default; }
  .document-results:hover {
    background-color: #FBFBFB;
    color: #000000; }

.image-search-results {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 5px;
  padding: 8px 20px;
  font-size: 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .image-search-results .image-search-display {
    height: 75px;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 3px; }
  .image-search-results .image-search-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 50%; }
    .image-search-results .image-search-list .image-search-date {
      color: #A5A5A5; }
  .image-search-results:hover {
    background-color: #FBFBFB;
    color: #000000; }

.display-result {
  display: inline-block;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle; }
  .display-result.result-container {
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
    background-color: #EAEDF0; }
    .display-result.result-container .result-name {
      padding: 0 5px; }
    .display-result.result-container .result-plus {
      font-size: 18px; }
    .display-result.result-container span {
      cursor: pointer; }
