/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.header {
  background: #000000; }
  .header .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 18px 30px;
    max-width: 1400px; }
  .header .user-wrapper {
    margin: auto;
    max-width: 1400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 499px) {
      .header .user-wrapper {
        padding: 18px 20px; } }
    @media (max-width: 1259px) {
      .header .user-wrapper {
        padding: 0;
        display: block;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .header .user-wrapper .user-header-container.building-profile {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin-left: 50px; }
      @media (max-width: 768px) {
        .header .user-wrapper .user-header-container.building-profile {
          width: auto; } }
  .header .mw-100 {
    max-width: 100%; }
  .header #masquerade_bar {
    z-index: 9999;
    background-color: #34495e;
    border: 2px solid #fff;
    position: fixed;
    height: 58.5px;
    border-radius: 0 0 5px 5px;
    width: 250px;
    top: -46px;
    margin-left: calc(50% - 125px);
    text-align: center;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s; }
    .header #masquerade_bar.hidden {
      display: none; }
    .header #masquerade_bar #masquerade_bar_inner {
      color: #fff;
      padding: 2px; }
      .header #masquerade_bar #masquerade_bar_inner a {
        color: #fff;
        text-decoration: none;
        cursor: pointer; }
  .header #masquerade_bar:hover {
    top: -5px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s; }
