/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .breadcrumbs .breadcrumb {
    color: rgba(53, 53, 53, .6);
    padding: 0 8px 0 0; }
  .breadcrumbs span.breadcrumb {
    word-break: break-word; }
  .breadcrumbs a.breadcrumb span {
    text-decoration: underline;
    color: #353535; }
  .breadcrumbs a.breadcrumb:after {
    content: '/';
    padding-left: 8px;
    text-decoration: none; }
  .breadcrumbs a.breadcrumb:hover {
    text-decoration: none; }
