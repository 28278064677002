/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.creditor-items-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px 10px; }
  .creditor-items-container .creditor-left {
    display: block;
    grid-column: 1 / span 2; }
  .creditor-items-container .creditor-right {
    display: block;
    grid-column: 3 / span 2; }
  .creditor-items-container .creditor-hidden {
    display: none;
    grid-column: 1 / span 4; }

.invoice-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px; }
  .invoice-grid .invoice-field-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .invoice-grid .invoice-field-info .invoice-field-title {
      font-weight: bold; }

.creditor-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px; }
  .creditor-fields .form__group {
    margin-bottom: 0; }

.compliance-status {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }
  .compliance-status.licence-status, .compliance-status.insurance-status {
    color: #fff;
    background-color: #6c757d;
    margin-right: 5px; }
  .compliance-status.licence-status--approved, .compliance-status.insurance-status--approved, .compliance-status.licence-status--valid, .compliance-status.insurance-status--valid {
    color: #fff;
    background-color: #28a745; }
  .compliance-status.licence-status--pending_verification, .compliance-status.licence-status--pending_approval, .compliance-status.insurance-status--pending_verification, .compliance-status.insurance-status--pending_approval {
    color: #212529;
    background-color: #ffc107; }
  .compliance-status.licence-status--rejected, .compliance-status.insurance-status--rejected, .compliance-status.licence-status--invalid, .compliance-status.insurance-status--invalid, .compliance-status.licence-status--expired, .compliance-status.insurance-status--expired {
    color: #fff;
    background-color: #dc3545; }
  .compliance-status.licence-status--not_required {
    color: #fff;
    background-color: #4A90E2; }
  .compliance-status.strata-master-status {
    color: #fff;
    background-color: #dc3545; }
  .compliance-status.strata-master-status--compliant, .compliance-status.strata-master-status--restricted, .compliance-status.strata-master-status--exempt {
    color: #fff;
    background-color: #4A90E2; }
