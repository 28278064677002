/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.invoice-row-items-row {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column: 1 / span 24; }
  .invoice-row-items-row .row-item-first-empty-col {
    grid-column: 1 / span 2; }
  .invoice-row-items-row .accordion-item {
    width: 100%;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    grid-column: 3 / span 22; }
    .invoice-row-items-row .accordion-item hr {
      margin: 0.625em 0 1em 0; }
    .invoice-row-items-row .accordion-item .row-item__header, .invoice-row-items-row .accordion-item .row-item__row {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(22, 1fr);
      font-size: 14px; }
    .invoice-row-items-row .accordion-item .row-item__row {
      font-weight: normal; }
    .invoice-row-items-row .accordion-item .row-item-first-col {
      grid-column: 1 / span 2; }
    .invoice-row-items-row .accordion-item .row-item-second-col {
      grid-column: 3 / span 5; }
    .invoice-row-items-row .accordion-item .row-item-third-col {
      grid-column: 8 / span 6; }
    .invoice-row-items-row .accordion-item .row-item-fourth-col {
      grid-column: 14 / span 2; }
    .invoice-row-items-row .accordion-item .row-item-fifth-col {
      grid-column: 16 / span 2; }
    .invoice-row-items-row .accordion-item .row-item-sixth-col {
      grid-column: 18 / span 2; }
