/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.building-inspection-session-dropdown-container {
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .building-inspection-session-dropdown-container .notification-indicator-wrapper {
    position: relative; }
    .building-inspection-session-dropdown-container .notification-indicator-wrapper .notification-indicator {
      position: absolute;
      top: -5px;
      right: -3px;
      background: #4FCBB2;
      width: 8px;
      height: 8px;
      border-radius: 4px; }
  .building-inspection-session-dropdown-container .icon-text {
    display: none;
    font-weight: normal; }
  @media (max-width: 768px) {
    .building-inspection-session-dropdown-container {
      padding: 10px 0; } }
  .building-inspection-session-dropdown-container .building-inspection-session-header-dropdown-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer; }
    @media (max-width: 1024px) {
      .building-inspection-session-dropdown-container .building-inspection-session-header-dropdown-container {
        margin-right: 20px; }
        .building-inspection-session-dropdown-container .building-inspection-session-header-dropdown-container .icon-text {
          display: block; } }
    @media (max-width: 768px) {
      .building-inspection-session-dropdown-container .building-inspection-session-header-dropdown-container {
        margin-right: 0; } }
    .building-inspection-session-dropdown-container .building-inspection-session-header-dropdown-container h4 {
      font-weight: 500; }
  @media (max-width: 768px) {
    .building-inspection-session-dropdown-container .building-inspection-session-title {
      font-size: 13px;
      color: #ffffff;
      letter-spacing: 1px; } }
  .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list {
    position: absolute;
    background: #000000;
    top: 49px;
    color: #ffffff;
    max-width: 400px;
    min-width: 300px;
    max-height: 93vh;
    overflow-y: auto;
    cursor: default;
    font-size: 13px;
    letter-spacing: 1px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 6px 8px 0 rgba(0, 0, 0, .1); }
    .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .loading {
      height: 500px; }
    .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .dropdown-divider {
      height: 0;
      margin: 8px 0;
      margin: 0.5rem 0;
      overflow: hidden;
      border-top: 1px solid #e9ecef; }
    .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-session-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 16px 0 8px 0;
      padding: 1rem 0 0.5rem 0; }
      .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-session-header .icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-right: 6px;
        margin-right: 0.375rem;
        text-align: center; }
        .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-session-header .icon::before {
          width: 15px;
          height: 15px;
          -webkit-mask-position: 25% 50%;
                  mask-position: 25% 50%; }
    .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-session-title {
      max-width: 100%;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-session-body {
      padding: 8px 20.8px;
      padding: 0.5rem 1.3rem;
      line-height: 1.25; }
    .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-session-footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: end;
      font-size: 85%;
      color: #aaa;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
    .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-dropdown-list-item {
      display: block;
      position: relative;
      padding: 12px 20px 12px 20px;
      padding: 0.75rem 20px 0.75rem 20px;
      line-height: 1.5;
      text-decoration: none; }
      .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-dropdown-list-item.mark-all-read {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; }
      .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-dropdown-list-item + .building-inspection-dropdown-list-item {
        border-top: 1px solid #e9ecef; }
    .building-inspection-session-dropdown-container .building-inspection-session-dropdown-list .building-inspection-session-actions {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-column-gap: 10px;
         -moz-column-gap: 10px;
              column-gap: 10px;
      font-size: 16px;
      padding: 16px 0 0 0;
      padding: 1rem 0 0 0; }
  .building-inspection-session-dropdown-container .current-building-inspection-session {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .building-inspection-session-dropdown-container .current-building-inspection-session .icon::before {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 25px;
      height: 25px; }
    .building-inspection-session-dropdown-container .current-building-inspection-session.text-danger {
      color: #d4504b; }

.building-inspection-dropdown-list-item:hover .notification-toggle-read.read {
  opacity: 1; }

.theme-dark .notification-section {
  color: #ffffff; }

.theme-dark a.building-inspection-dropdown-list-item:hover {
  background-color: rgba(255, 255, 255, .1); }

.theme-light .notification-section {
  color: #000000; }

.theme-light a.building-inspection-dropdown-list-item:hover {
  background-color: rgba(0, 0, 0, .1); }
