/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.download {
  text-decoration: underline;
  color: blue; }

.public-notice-item--cover-image-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.public-notice-item--cover-image {
  padding: 16px 16px 8px 16px;
  padding: 1rem 1rem .5rem 1rem;
  max-height: 200px;
  -o-object-fit: cover;
     object-fit: cover; }

.c-modal__body .public-notice-item--text {
  padding: 0;
  text-align: left;
  height: auto;
  overflow: inherit; }
  .c-modal__body .public-notice-item--text ol {
    list-style-type: decimal;
    list-style-position: inside; }
  .c-modal__body .public-notice-item--text ul {
    list-style-type: disc;
    list-style-position: inside; }
