/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.c-modal__header {
  position: relative;
  margin-bottom: 16px;
  margin-bottom: 1rem;
  border-radius: .2rem; }

.c-modal__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  padding: 16px 24px;
  padding: 1rem 1.5rem;
  background: #000000;
  color: #ffffff;
  font-weight: 700;
  font-size: 20.8px;
  font-size: 1.3rem;
  line-height: 20.8px;
  line-height: 1.3rem; }

.c-modal__header-close {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: 1px solid transparent;
  font-size: 36.8px;
  font-size: 2.3rem;
  padding: 4.8px 24px 0 0;
  padding: .3rem 1.5rem 0 0;
  color: #ffffff; }
  .c-modal__header-close:hover {
    color: #999999; }

.c-modal__body {
  padding: 0 24px;
  padding: 0 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.c-modal__footer {
  text-align: right;
  margin-bottom: 16px;
  margin-bottom: 1rem;
  padding: 0 24px;
  padding: 0 1.5rem; }

.c-modal__container {
  border-radius: .2rem;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
          box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  max-width: 900px;
  min-width: 500px;
  width: auto;
  border: none !important; }
  @media (max-width: 1024px) {
    .c-modal__container {
      min-width: auto;
      width: 90vw; } }

.c-modal__container--lg {
  max-width: 90vw; }
