/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.file-upload-top {
  grid-column: 1/4;
  display: grid;
  grid-template-columns: 55px auto 30px; }
  .file-upload-top .file-type-icon {
    grid-column: 1/2;
    width: 35px;
    height: 45px;
    margin: 5px auto auto auto; }
  .file-upload-top .uploaded-top-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    grid-column: 2/3; }
    .file-upload-top .uploaded-top-details .file-name {
      margin: 0;
      font-weight: bold; }
    .file-upload-top .uploaded-top-details .file-size {
      color: #8697a8;
      font-size: 13px;
      margin-bottom: 10px; }
  .file-upload-top .delete-cross {
    background: none;
    border-style: none;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start; }
    .file-upload-top .delete-cross:before {
      height: 12px;
      width: 12px;
      background-color: #44566c;
      border: 3px solid #44566c;
      border-radius: 50%;
      cursor: pointer; }
