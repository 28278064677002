/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dms-header {
  padding-top: 20px; }

.dms-header--top, .dms-header--bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 15px; }

.dms-header--bottom > .dms-search {
  min-width: 350px;
  max-width: 700px; }

.dms-header-title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .dms-header-title-wrapper h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2em; }
  .dms-header-title-wrapper .tag {
    margin: 0 0 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .dms-header-title-wrapper .dms-header-title {
    font-size: 24px;
    padding: 10px;
    border-radius: 4px;
    color: #333333; }
    .dms-header-title-wrapper .dms-header-title.active {
      background: #ddd; }
    .dms-header-title-wrapper .dms-header-title.inactive:hover {
      cursor: pointer;
      background: #ddd; }
  .dms-header-title-wrapper .invoices {
    position: relative; }
  .dms-header-title-wrapper .badge {
    font-size: 50%;
    position: absolute;
    top: -10px;
    right: -15px;
    cursor: pointer; }
  .dms-header-title-wrapper .badge:hover {
    opacity: .7; }

.dms-filters, .dms-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .dms-filters .button__grid-view, .dms-filters .button__list-view, .dms-actions .button__grid-view, .dms-actions .button__list-view {
    border: none;
    padding: 11px 12px;
    color: #333333;
    border-radius: 50%; }
    .dms-filters .button__grid-view.inactive, .dms-filters .button__list-view.inactive, .dms-actions .button__grid-view.inactive, .dms-actions .button__list-view.inactive {
      background: #ddd; }
    .dms-filters .button__grid-view:hover, .dms-filters .button__list-view:hover, .dms-actions .button__grid-view:hover, .dms-actions .button__list-view:hover {
      background: #4FCBB2;
      color: #333333; }
    .dms-filters .button__grid-view.active, .dms-filters .button__list-view.active, .dms-actions .button__grid-view.active, .dms-actions .button__list-view.active {
      background: #4FCBB2;
      color: #333333; }
  .dms-filters .button__grid-view, .dms-actions .button__grid-view {
    margin-left: 10px; }
  .dms-filters .button__financial-report,
  .dms-filters .button__levy-notice,
  .dms-filters .button__add,
  .dms-filters .button__added-date-filter,
  .dms-filters .button__expanded-view, .dms-actions .button__financial-report,
  .dms-actions .button__levy-notice,
  .dms-actions .button__add,
  .dms-actions .button__added-date-filter,
  .dms-actions .button__expanded-view {
    background: #4FCBB2;
    border: none;
    padding: 11px 12px;
    color: #333333;
    border-radius: 50%; }
    .dms-filters .button__financial-report.inactive,
    .dms-filters .button__levy-notice.inactive,
    .dms-filters .button__add.inactive,
    .dms-filters .button__added-date-filter.inactive,
    .dms-filters .button__expanded-view.inactive, .dms-actions .button__financial-report.inactive,
    .dms-actions .button__levy-notice.inactive,
    .dms-actions .button__add.inactive,
    .dms-actions .button__added-date-filter.inactive,
    .dms-actions .button__expanded-view.inactive {
      background: #ddd; }
    .dms-filters .button__financial-report:hover, .dms-filters .button__financial-report.active,
    .dms-filters .button__levy-notice:hover,
    .dms-filters .button__levy-notice.active,
    .dms-filters .button__add:hover,
    .dms-filters .button__add.active,
    .dms-filters .button__added-date-filter:hover,
    .dms-filters .button__added-date-filter.active,
    .dms-filters .button__expanded-view:hover,
    .dms-filters .button__expanded-view.active, .dms-actions .button__financial-report:hover, .dms-actions .button__financial-report.active,
    .dms-actions .button__levy-notice:hover,
    .dms-actions .button__levy-notice.active,
    .dms-actions .button__add:hover,
    .dms-actions .button__add.active,
    .dms-actions .button__added-date-filter:hover,
    .dms-actions .button__added-date-filter.active,
    .dms-actions .button__expanded-view:hover,
    .dms-actions .button__expanded-view.active {
      background: #4FCBB2;
      color: #333333; }
  .dms-filters .button__levy-notice, .dms-actions .button__levy-notice {
    padding: 11px 15px; }
  .dms-filters .button__added-date-filter, .dms-actions .button__added-date-filter {
    padding: 11px 14px; }
  .dms-filters .button__add, .dms-actions .button__add {
    color: black;
    position: relative; }
  .dms-filters .add-button-wrapper, .dms-actions .add-button-wrapper {
    position: relative;
    margin-left: 10px; }
    .dms-filters .add-button-wrapper .document-upload-dropdown, .dms-actions .add-button-wrapper .document-upload-dropdown {
      position: absolute;
      top: calc(100% + 10px);
      right: 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      background-color: white;
      z-index: 999;
      padding: 10px 0px;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
              box-shadow: 0 0 10px rgba(0, 0, 0, .1);
      border-radius: 5px; }
      .dms-filters .add-button-wrapper .document-upload-dropdown::before, .dms-actions .add-button-wrapper .document-upload-dropdown::before {
        content: '';
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 5px solid #ffffff;
        position: absolute;
        top: -29px;
        right: 12px;
        z-index: 99;
        margin-top: 25px; }
      .dms-filters .add-button-wrapper .document-upload-dropdown span, .dms-actions .add-button-wrapper .document-upload-dropdown span {
        padding: 10px 25px;
        white-space: nowrap; }
        .dms-filters .add-button-wrapper .document-upload-dropdown span:hover, .dms-actions .add-button-wrapper .document-upload-dropdown span:hover {
          cursor: pointer;
          text-decoration: underline; }

.dms-filters > .dms-filter {
  margin: 10px 0 0 10px; }

.dms-filters > .dms-filter:first-child {
  margin-left: 0; }

.dms-actions > button, .dms-actions > .button-group, .dms-actions > .dms-date-filter {
  margin-left: 10px; }
  @media (max-width: 1024px) {
    .dms-actions > button, .dms-actions > .button-group, .dms-actions > .dms-date-filter {
      margin-top: 10px; } }

.dms-actions > *:first-child {
  margin-left: 0; }

.dms-date-filter {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.dms-date-filter__input {
  min-width: 230px;
  padding-left: 30px; }
