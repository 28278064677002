/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dms-grid-view-item {
  background: #ffffff;
  border-radius: 5px;
  border: #eaeaea 1px solid;
  padding: 15px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .dms-grid-view-item .dms-thumbnail-img {
    width: 250px;
    height: 350px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center; }
    @media (min-width: 500px) and (max-width: 1259px) {
      .dms-grid-view-item .dms-thumbnail-img {
        width: 150px;
        height: 210px; } }
  .dms-grid-view-item.red-border {
    border-color: #e8a09d; }
  .dms-grid-view-item.bg-light-red {
    background-color: #ffe8e7; }
  .dms-grid-view-item .invoice-status {
    border: 1px solid #000000;
    color: #333333;
    text-align: center;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 3px 7px; }
    .dms-grid-view-item .invoice-status.pending {
      border-color: purple;
      color: purple; }
    .dms-grid-view-item .invoice-status.under-review {
      border-color: #4A90E2;
      color: #4A90E2; }
    .dms-grid-view-item .invoice-status.processing, .dms-grid-view-item .invoice-status.extracting {
      color: #F5BF65; }
    .dms-grid-view-item .invoice-status.on-hold {
      border-color: #e0730d;
      color: #e0730d; }
    .dms-grid-view-item .invoice-status.approved {
      border-color: #4FCBB2;
      color: #4FCBB2; }
    .dms-grid-view-item .invoice-status.cancelled, .dms-grid-view-item .invoice-status.failed, .dms-grid-view-item .invoice-status.rejected, .dms-grid-view-item .invoice-status.processing-failed {
      border-color: #D4504B;
      color: #D4504B; }

.dms-grid-view-item-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .dms-grid-view-item-actions .dms-grid-view-item-actions-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .dms-grid-view-item-actions .dropdown {
    width: 200px;
    font-size: 14px; }
  .dms-grid-view-item-actions .dms-grid-view-item-actions-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .dms-grid-view-item-actions .dms-grid-view-item-actions-right .dropdown-wrapper .label {
      padding: 0;
      width: 24px;
      height: 24px;
      font-size: 20px;
      padding-bottom: 11.5px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .dms-grid-view-item-actions .dms-grid-view-item-actions-right a.schedule-date,
    .dms-grid-view-item-actions .dms-grid-view-item-actions-right a.job-link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-right: 1.6px;
      margin-right: .1rem;
      background-color: white;
      border: #f4f4f4 1px solid;
      border-radius: 50%;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 24px;
      height: 24px; }
      .dms-grid-view-item-actions .dms-grid-view-item-actions-right a.schedule-date span,
      .dms-grid-view-item-actions .dms-grid-view-item-actions-right a.job-link span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .dms-grid-view-item-actions .dms-grid-view-item-actions-right a.schedule-date .icon:before,
      .dms-grid-view-item-actions .dms-grid-view-item-actions-right a.job-link .icon:before {
        width: 13px;
        height: 13px; }

.dms-grid-view-item-body {
  text-align: center;
  padding: 10px 0 0;
  overflow: hidden; }
  .dms-grid-view-item-body:hover {
    text-decoration: none; }
  .dms-grid-view-item-body .file-type-icon {
    margin: 0 auto 10px; }
  .dms-grid-view-item-body .dms-grid-view-item-container {
    position: relative;
    cursor: pointer; }
    .dms-grid-view-item-body .dms-grid-view-item-container .icon {
      top: 0;
      right: 0;
      background: #333333;
      padding: 0 5px; }
  .dms-grid-view-item-body .dms-grid-item-name, .dms-grid-view-item-body .dms-grid-item-category {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    width: 100%; }
  .dms-grid-view-item-body .dms-grid-item-name {
    color: #353535; }
  .dms-grid-view-item-body .dms-grid-item-category {
    color: #8E8E8E;
    text-transform: capitalize; }
  .dms-grid-view-item-body .dms-grid-item-invoice-status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .dms-grid-view-item-body .dms-grid-item-invoice-status .duplicate-icon {
      cursor: pointer;
      margin-right: 4.8px;
      margin-right: .3rem; }

.dms-grid-view-item-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 0 0;
  font-size: 14px;
  color: rgba(53, 53, 53, .6); }
