/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.fields.invoice-items-container > .field {
  grid-column: auto; }

.fields.invoice-items-container .link-job-btn {
  cursor: pointer; }

.funding-status--created, .funding-status--active {
  padding: 3.2px 8px;
  padding: .2rem .5rem;
  border-radius: .2rem;
  background-color: #F5BF65;
  color: white; }

.funding-status--completed {
  padding: 3.2px 8px;
  padding: .2rem .5rem;
  border-radius: .2rem;
  background-color: #4FCBB2;
  color: white; }

.funding-status--indefault {
  padding: 3.2px 8px;
  padding: .2rem .5rem;
  border-radius: .2rem;
  background-color: #D4504B;
  color: white; }
