/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.marker-container {
  position: relative; }
  .marker-container .hover-info {
    position: absolute;
    padding: 5px;
    width: 120px;
    background-color: #ffffff;
    text-align: center;
    border-radius: 5px;
    z-index: 999;
    bottom: 20px;
    left: -60px;
    font-size: 14px; }
    .marker-container .hover-info.inactive {
      display: none; }
  .marker-container .marker {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #4FCBB2;
    position: absolute;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px; }
  .marker-container .marker:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #EAEAEA;
    position: absolute;
    border-radius: 50%; }
  .marker-container .pulse {
    background: rgba(0, 0, 0, .2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    -webkit-transform: rotateX(55deg);
            transform: rotateX(55deg);
    z-index: -2; }
  .marker-container .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    -webkit-animation: pulsate 1s ease-out;
            animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    opacity: 0;
    -webkit-box-shadow: 0 0 1px 2px #4FCBB2;
            box-shadow: 0 0 1px 2px #4FCBB2;
    -webkit-animation-delay: 1.1s;
            animation-delay: 1.1s; }

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0; } }

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0; } }

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg); }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg); }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg); } }

@keyframes bounce {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg); }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg); }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg); } }
