/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.other-locations-modal .locations-wrapper {
  margin-top: 20px;
  padding: 10px; }
  .other-locations-modal .locations-wrapper ul {
    display: block;
    list-style-type: disc;
    -webkit-padding-start: 20px;
            padding-inline-start: 20px; }
