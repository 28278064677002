/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dms-search {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .dms-search form {
    width: 100%;
    margin-right: 10px; }
    @media (min-width: 1260px) {
      .dms-search form {
        max-width: 530px; } }
  .dms-search button.button.button--search-tip {
    background: #EAEDF0;
    padding: 10.5px;
    border-radius: 4px;
    color: #333333; }
    .dms-search button.button.button--search-tip:hover {
      background: #ccd4db; }
  @media (max-width: 659px) {
    .dms-search {
      max-width: inherit; } }
  @media (min-width: 1260px) {
    .dms-search {
      max-width: 580px; } }

.dms-search-group {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.dms-search-input {
  width: 100%;
  border-left: none;
  border-radius: 0 4px 4px 0;
  padding-left: 5px; }

.dms-search-dropdown {
  position: absolute;
  top: 44px;
  left: 0;
  background: #ffffff;
  width: 100%;
  z-index: 100;
  max-width: 530px; }

.dms-search-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .dms-search-box input:focus {
    -webkit-transition: none;
    transition: none; }
  .dms-search-box input:not(:focus) {
    -webkit-transition: none;
    transition: none; }
  .dms-search-box .dms-search-tags {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px 0 0 4px;
    margin-right: -5px;
    padding: 0 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .dms-search-box .dms-search-tags span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #4FCBB2;
      color: #ffffff;
      border-radius: 4px;
      padding: 5px;
      height: 34px;
      margin: 0 2px;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      text-transform: capitalize; }
      .dms-search-box .dms-search-tags span .icon-after {
        padding-right: 0;
        cursor: pointer; }
        .dms-search-box .dms-search-tags span .icon-after:after {
          width: 10px;
          height: 10px; }
  .dms-search-box .active {
    border-color: #4fcbb2; }
