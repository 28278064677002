/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dms-sort-options {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(24, 1fr);
  padding: 10px; }
  @media (max-width: 499px) {
    .dms-sort-options {
      padding: 5px;
      grid-gap: 5px; } }
  .dms-sort-options.document-view {
    min-width: 720px; }
  .dms-sort-options.invoice-view {
    min-width: 975px; }

.dms-sort-option {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700; }
  .dms-sort-option:hover {
    text-decoration: none; }
  .dms-sort-option:after {
    width: 9px;
    height: 9px;
    margin: 0 0 0 10px;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .dms-sort-option.active:after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  .dms-sort-option.doc-select-all {
    grid-column: 1 / span 3; }
  .dms-sort-option.doc-sort-sp {
    grid-column: 4 / span 3; }
  .dms-sort-option.doc-sort-type {
    grid-column: 7 / span 3; }
  .dms-sort-option.doc-sort-name {
    grid-column: 10 / span 9; }
  .dms-sort-option.doc-sort-date {
    grid-column: 19 / span 3; }
  .dms-sort-option.doc-actions-header {
    grid-column: 22 / span 3; }
  .dms-sort-option.invoice-select-all {
    grid-column: 1 / span 2; }
  .dms-sort-option.invoice-sp-number {
    grid-column: 3 / span 2; }
  .dms-sort-option.invoice-sort-contractor {
    grid-column: 5 / span 4; }
  .dms-sort-option.invoice-sort-status {
    grid-column: 9 / span 3; }
  .dms-sort-option.invoice-sort-number {
    grid-column: 12 / span 2; }
  .dms-sort-option.invoice-sort-amount {
    grid-column: 14 / span 2; }
  .dms-sort-option.invoice-sort-date {
    grid-column: 16 / span 2; }
  .dms-sort-option.invoice-sort-currently-with {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    grid-column: 18 / span 2; }
  .dms-sort-option.sort-currently-time-with {
    grid-column: 20 / span 2; }
  .dms-sort-option.funding-status {
    grid-column: 18 / span 2; }
  .dms-sort-option.settlement-date {
    grid-column: 20 / span 2; }
  .dms-sort-option.invoice-paid-date {
    grid-column: 22 / span 2; }
  .dms-sort-option.invoice-actions-header {
    grid-column: 24 / span 2; }

.document-table__headers--for-contractor {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(24, 1fr);
  padding: 10px; }
  @media (max-width: 499px) {
    .document-table__headers--for-contractor {
      padding: 5px;
      grid-gap: 5px; } }
  .document-table__headers--for-contractor.document-view {
    min-width: 720px; }
  .document-table__headers--for-contractor.invoice-view {
    min-width: 975px; }
  .document-table__headers--for-contractor .document-table__header {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700; }
    .document-table__headers--for-contractor .document-table__header:hover {
      text-decoration: none; }
    .document-table__headers--for-contractor .document-table__header:after {
      width: 9px;
      height: 9px;
      margin: 0 0 0 10px;
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s; }
    .document-table__headers--for-contractor .document-table__header.active:after {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg); }
  .document-table__headers--for-contractor .document-table__header__select-all-checkbox {
    grid-column: 1 / span 2; }
  .document-table__headers--for-contractor .document-table__header__sp-number {
    grid-column: 3 / span 2; }
  .document-table__headers--for-contractor .document-table__header__invoice-status {
    grid-column: 5 / span 2; }
  .document-table__headers--for-contractor .document-table__header__invoice-number {
    grid-column: 7 / span 3; }
  .document-table__headers--for-contractor .document-table__header__invoice-amount {
    grid-column: 10 / span 2; }
  .document-table__headers--for-contractor .document-table__header__invoice-date {
    grid-column: 12 / span 3; }
  .document-table__headers--for-contractor .document-table__header__funding-status {
    grid-column: 15 / span 3; }
  .document-table__headers--for-contractor .document-table__header__settlement-date {
    grid-column: 18 / span 3; }
  .document-table__headers--for-contractor .document-table__header__invoice-paid-date {
    grid-column: 21 / span 3; }

.document-table__headers .icon-after.active:after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
