/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.delete-modal-container {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  left: 0; }
  .delete-modal-container .delete-modal-container-inner {
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;
    text-align: center;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 25px 30px;
    margin: auto;
    min-width: 400px;
    width: 550px;
    min-height: 120px;
    border-radius: 6px; }
    .delete-modal-container .delete-modal-container-inner .delete_modal_btn_box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin: 35px auto 0;
      max-width: 100%;
      width: 40%; }
      .delete-modal-container .delete-modal-container-inner .delete_modal_btn_box button {
        color: #fff;
        padding: 0;
        width: 85px;
        height: 32px;
        border-radius: 8px;
        border: none;
        font-weight: 600;
        font-size: 16px; }
        .delete-modal-container .delete-modal-container-inner .delete_modal_btn_box button.cancel-btn {
          background: #2f9f88; }
        .delete-modal-container .delete-modal-container-inner .delete_modal_btn_box button.delete-btn {
          background: #4c4747; }
        .delete-modal-container .delete-modal-container-inner .delete_modal_btn_box button:hover {
          -webkit-box-shadow: 0 1px 4px 0 rgba(12, 12, 13, .2), 0 0 0 1px rgba(0, 0, 0, .15);
                  box-shadow: 0 1px 4px 0 rgba(12, 12, 13, .2), 0 0 0 1px rgba(0, 0, 0, .15); }
