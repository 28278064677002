/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.password-error {
  position: absolute;
  opacity: 1;
  top: 90px;
  z-index: 3;
  background: rgba(64, 64, 64, .9);
  color: #ffffff;
  padding: 6px 12px;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  line-height: 1.4em;
  border-radius: 10px;
  -webkit-transition: opacity 0.3s 0s linear, bottom 0.3s 0s;
  transition: opacity 0.3s 0s linear, bottom 0.3s 0s; }
  .password-error.inactive {
    opacity: 0;
    -webkit-transition: opacity 0.3s 0.2s linear, bottom 0.3s 0.2s;
    transition: opacity 0.3s 0.2s linear, bottom 0.3s 0.2s; }
  .password-error:before {
    content: '';
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(64, 64, 64, .9);
    position: absolute;
    top: -5px;
    right: 60px; }
  .password-error .error-message {
    font-size: 15px;
    font-weight: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .password-error ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 0.5em;
            margin-block-start: 0.5em;
    -webkit-margin-after: 0.5em;
            margin-block-end: 0.5em;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    -webkit-padding-start: 20px;
            padding-inline-start: 20px; }
