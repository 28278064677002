/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.avatars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .avatars .avatar {
    margin-right: -5px; }

.avatar {
  display: inline-block;
  text-align: center;
  text-transform: uppercase; }
  .avatar .avatar-photo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 50px;
    height: 50px;
    border: #e0e0e0 1px solid;
    margin: 5px 0;
    border-radius: 50%;
    background-color: #4FCBB2;
    background-size: cover;
    background-position: center;
    font-size: 26px;
    font-weight: 900;
    letter-spacing: -1px;
    color: #ffffff; }
    .avatar .avatar-photo .avatar-letter {
      font-size: 12px; }
    .avatar .avatar-photo.with-tooltip {
      cursor: pointer; }
    .avatar .avatar-photo.approver {
      border: 1px solid black !important;
      background-color: black !important; }
    .avatar .avatar-photo.disabled {
      border: 1px solid #EAEDF0 !important;
      background-color: #EAEDF0 !important; }
    .avatar .avatar-photo.approver-green {
      border: 4px solid #0bc50b !important;
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
      -webkit-filter: grayscale(0);
              filter: grayscale(0); }
    .avatar .avatar-photo.approver-orange {
      border: 4px solid #f5a107 !important;
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
      -webkit-filter: grayscale(0);
              filter: grayscale(0); }
    .avatar .avatar-photo.approver-red {
      border: 4px solid #ff0000 !important;
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
      -webkit-filter: grayscale(0);
              filter: grayscale(0); }
    .avatar .avatar-photo.approver-assigned {
      color: #4FCBB2 !important; }
    .avatar .avatar-photo.tiny {
      width: 22px;
      height: 22px;
      font-size: 11px; }
      .avatar .avatar-photo.tiny .avatar-letter {
        font-size: 11px; }
    .avatar .avatar-photo.xsmall {
      width: 30px;
      height: 30px;
      font-size: 12px; }
      .avatar .avatar-photo.xsmall .avatar-letter {
        font-size: 12px; }
    .avatar .avatar-photo.small {
      width: 40px;
      height: 40px;
      font-size: 14px; }
      .avatar .avatar-photo.small .avatar-letter {
        font-size: 14px; }
    .avatar .avatar-photo.small-to-medium {
      width: 60px;
      height: 60px;
      font-size: 22px; }
      .avatar .avatar-photo.small-to-medium .avatar-letter {
        font-size: 22px; }
    .avatar .avatar-photo.medium {
      width: 100px;
      height: 100px;
      font-size: 26px; }
      .avatar .avatar-photo.medium .avatar-letter {
        font-size: 26px; }
    .avatar .avatar-photo.large {
      width: 150px;
      height: 150px;
      font-size: 36px; }
      .avatar .avatar-photo.large .avatar-letter {
        font-size: 36px; }
