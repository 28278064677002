/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.flash {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: -60px;
  left: 50%;
  width: 100%;
  min-width: 300px;
  max-width: 680px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  z-index: 9999999;
  padding: 12px 20px;
  padding: .75rem 1.25rem;
  border-radius: .25rem;
  border: 1px solid transparent; }

.flash--active {
  top: 10px;
  -webkit-transition: top 0.6s 0.3s;
  transition: top 0.6s 0.3s; }

.flash--success {
  color: #1d6455;
  background-color: #c5eee6;
  border-color: #4FCBB2; }

.flash--error, .flash--danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }

.flash--warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.flash--info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }

.flash__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .flash__text:before {
    margin-right: 10px; }

.flash__close {
  background-color: transparent;
  padding: 0;
  border: 0;
  opacity: .7; }
  .flash__close:hover {
    opacity: 1; }

.flash__icon {
  margin-right: 10px; }
