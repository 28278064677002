/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.upcoming-work-item {
  border: 1.5px solid #DFDFDF;
  margin: 15px 0;
  padding: 15px;
  position: relative;
  border-radius: 10px; }
  .upcoming-work-item:first-of-type {
    margin-top: 0; }
  .upcoming-work-item:hover {
    background-color: #F8F8F8; }
  .upcoming-work-item .upcoming-work-timestamps {
    font-size: 12px;
    font-size: .75rem; }
  .upcoming-work-item .recurring-rule-range {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .upcoming-work-item p {
    margin-bottom: 15px; }
  .upcoming-work-item hr {
    border: 1.5px solid #DFDFDF;
    border-radius: 0.75px;
    margin: 10px 0 15px 0; }
  .upcoming-work-item .upcoming-trade-item {
    border: .2px solid #DFDFDF;
    margin-bottom: 10px;
    position: relative; }
  .upcoming-work-item .upcoming-trade-item:last-of-type {
    margin-bottom: 5px; }
  .upcoming-work-item .upcoming-trade-item--header {
    padding: 5px 10px;
    background-color: black;
    color: white; }
  .upcoming-work-item .upcoming-trade-item--body {
    padding: 5px 10px;
    font-size: 13.6px;
    font-size: .85rem; }
