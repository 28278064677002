/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.filter-buttons {
  margin-bottom: 10px; }

.digital-noticeboard-item {
  margin-bottom: 10px;
  line-height: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .digital-noticeboard-item .digital-noticeboard-item--info {
    padding: 0 10px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .digital-noticeboard-item .digital-noticeboard-item__time-frame {
    padding: 6.4px 0;
    padding: 0.4rem 0; }
  .digital-noticeboard-item .digital-noticeboard-item__screen-name-and-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .digital-noticeboard-item .digital-noticeboard-preview-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 0;
    background-color: #fff;
    max-width: 100px; }
  .digital-noticeboard-item .digital-noticeboard-preview {
    padding: 8px 4px 2px 4px; }
  .digital-noticeboard-item .digital-noticeboard-preview__header {
    margin-bottom: 3.2px; }
  .digital-noticeboard-item .digital-noticeboard-preview__content {
    margin-bottom: 4px;
    height: 137px; }
  .digital-noticeboard-item .digital-noticeboard-preview__message-box--primary {
    border-radius: 4px; }
  .digital-noticeboard-item .digital-noticeboard-preview__heading-text {
    padding: 8px 5px 0 5px;
    font-size: 6.25px;
    line-height: 1.3;
    font-weight: 550;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2; }
  .digital-noticeboard-item .digital-noticeboard-preview__main-copy-text {
    padding: 5px 5px 0 5px;
    font-size: 4.75px;
    line-height: 1.3; }
  .digital-noticeboard-item .digital-noticeboard-preview__message-box--alert > .digital-noticeboard-preview__main-copy-text {
    -webkit-line-clamp: 7;
    /* number of lines to show */
    line-clamp: 7; }
  .digital-noticeboard-item .digital-noticeboard-preview__message-box--message > .digital-noticeboard-preview__main-copy-text {
    -webkit-line-clamp: 15;
    /* number of lines to show */
    line-clamp: 15; }
  .digital-noticeboard-item .digital-noticeboard-preview__message-box--news > .digital-noticeboard-preview__main-copy-text {
    -webkit-line-clamp: 7;
    /* number of lines to show */
    line-clamp: 7; }
  .digital-noticeboard-item .digital-noticeboard-preview__message-box--welcome > .digital-noticeboard-preview__main-copy-text {
    -webkit-line-clamp: 8;
    /* number of lines to show */
    line-clamp: 8; }
  .digital-noticeboard-item .digital-noticeboard-preview__message-box--news {
    border-top-left-radius: unset;
    border-top-right-radius: unset; }
  .digital-noticeboard-item .digital-noticeboard-preview__news-image-placeholder {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .digital-noticeboard-item .digital-noticeboard-preview__news-image-placeholder span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 6.25px;
      font-weight: 550; }
  .digital-noticeboard-item .digital-noticeboard-preview__news-image {
    padding-bottom: 2px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .digital-noticeboard-item .digital-noticeboard-preview__extra-copy-and-qr {
    margin-top: 4px;
    gap: 4px; }
  .digital-noticeboard-item .digital-noticeboard-preview__extra-copy-text {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    border-radius: 4px;
    padding: 3px;
    font-size: 3.25px;
    line-height: 1.3;
    -webkit-line-clamp: 8;
    /* number of lines to show */
    line-clamp: 8; }
  .digital-noticeboard-item .digital-noticeboard-preview__qr-image-placeholder {
    border-radius: 4px;
    padding: 3px; }
    .digital-noticeboard-item .digital-noticeboard-preview__qr-image-placeholder span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 11.8px;
      font-weight: 550; }
  .digital-noticeboard-item .digital-noticeboard-preview__clock {
    padding: 1px 1.4px;
    border-radius: 1.4px;
    font-weight: 550;
    font-size: 5px; }
  .digital-noticeboard-item .digital-noticeboard-preview__message-box--secondary {
    padding: 3px;
    border-radius: 4px; }
  .digital-noticeboard-item .digital-noticeboard-preview__event-date {
    position: absolute;
    top: -2.5px;
    left: -4px;
    padding: 10px 2.5px 10px 10px;
    width: 45px;
    height: 45px; }
    .digital-noticeboard-item .digital-noticeboard-preview__event-date .digital-noticeboard-preview__event-month {
      font-weight: 520;
      font-size: 9px;
      letter-spacing: -0.5px; }
    .digital-noticeboard-item .digital-noticeboard-preview__event-date .digital-noticeboard-preview__event-day-of-month {
      font-weight: 700;
      font-size: 19px;
      letter-spacing: -1px; }
      .digital-noticeboard-item .digital-noticeboard-preview__event-date .digital-noticeboard-preview__event-day-of-month sup {
        letter-spacing: -0.6px;
        font-weight: 520;
        font-size: 8.5px; }
  .digital-noticeboard-item .digital-noticeboard-preview__event-time {
    margin-left: 40px;
    font-weight: 510;
    font-size: 8.5px; }
  .digital-noticeboard-item .digital-noticeboard-preview__event-week-day {
    margin-left: 40px;
    font-weight: 800;
    font-size: 12.5px; }
  .digital-noticeboard-item .digital-noticeboard-preview__event-heading {
    margin-top: 7.5px;
    font-size: 12px;
    font-weight: 1000;
    letter-spacing: -.5px; }
  .digital-noticeboard-item .digital-noticeboard-preview__event-description {
    margin-top: 2.5px;
    font-size: 7.5px; }
  .digital-noticeboard-item .digital-noticeboard-preview__event-location {
    margin-top: 10px;
    gap: 7.5px; }
    .digital-noticeboard-item .digital-noticeboard-preview__event-location .marker {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 10px;
              flex: 0 0 10px;
      width: 10px;
      height: 10px; }
      .digital-noticeboard-item .digital-noticeboard-preview__event-location .marker:after {
        content: '';
        width: 5px;
        height: 5px;
        margin: 5px 0 0 5px; }
    .digital-noticeboard-item .digital-noticeboard-preview__event-location .digital-noticeboard-preview__event-location-text {
      font-size: 7.5px;
      font-weight: 520; }
  .digital-noticeboard-item .digital-noticeboard-item__status {
    font-weight: 600;
    color: #ffffff;
    background-color: #333333;
    padding: 3px 5px;
    border-radius: 5px; }
    .digital-noticeboard-item .digital-noticeboard-item__status.digital-noticeboard-item--active {
      color: #ffffff;
      background-color: #4FCBB2; }
    .digital-noticeboard-item .digital-noticeboard-item__status.digital-noticeboard-item--draft {
      color: #ffffff;
      background-color: #F5BF65; }
    .digital-noticeboard-item .digital-noticeboard-item__status.digital-noticeboard-item--scheduled {
      color: #ffffff;
      background-color: #4A90E2; }
