/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.search-tip-contents .modal-content {
  max-width: 620px; }

.search-tip-contents .bold {
  font-weight: bold; }

.search-tip-contents .title {
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize; }

.search-tip-contents p {
  margin-bottom: 10px; }

.search-tip-contents ol {
  list-style: decimal;
  padding-left: 24px;
  padding-left: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }
  .search-tip-contents ol ::marker {
    font-weight: bold; }

.search-tip-contents ul {
  list-style: disc;
  padding-left: 15px;
  margin-left: 25px;
  margin-bottom: 15px; }

.search-tip-contents .mb-0 {
  margin-bottom: 0; }

.search-tip-contents .italic {
  font-style: italic; }

.search-tip-modal-container .react-tabs {
  margin-top: -16px;
  margin-top: -1rem; }

.search-tip-modal-container ul.react-tabs__tab-list {
  margin: 0 0 32px;
  margin: 0 0 2rem;
  background-color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 5px; }

.search-tip-modal-container .react-tabs__tab {
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  width: 30%; }

.search-tip-modal-container .react-tabs__tab--selected {
  background: #D8E5EE;
  border-color: #ffffff;
  color: #000000;
  border-radius: 5px 5px 0 0;
  margin-bottom: -5px; }
