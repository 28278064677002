/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.validation-error {
  position: absolute;
  top: auto;
  bottom: -25px;
  left: 10px;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  background: rgba(212, 80, 75, .9);
  color: #ffffff;
  max-width: 250px;
  padding: 6px 12px;
  font-size: 14px;
  text-align: left;
  line-height: 1.4em;
  border-radius: 3px;
  -webkit-transition: opacity 0.3s 0s linear, bottom 0.3s 0s;
  transition: opacity 0.3s 0s linear, bottom 0.3s 0s; }
  .validation-error.active {
    bottom: -30px;
    z-index: 6;
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 0.3s 0.2s linear, bottom 0.3s 0.2s;
    transition: opacity 0.3s 0.2s linear, bottom 0.3s 0.2s; }
  .validation-error:before {
    content: '';
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(212, 80, 75, .9);
    position: absolute;
    top: -5px;
    left: 18px; }
