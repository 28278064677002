/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dms-preview-modal .react-pdf__Page__canvas {
  margin: auto !important;
  height: auto !important;
  max-width: 595px;
  width: 100% !important; }

.dms-preview-modal .dms-preview-modal-content {
  display: block;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 48px 24px 112px; }

.dms-preview-modal .error-msg {
  color: white;
  background: rgba(0, 0, 0, .8);
  padding: 5px 10px;
  border-radius: 5px; }

.dms-preview-modal .btn-icon {
  margin-right: 8px; }

.dms-preview-modal .file-info {
  color: white;
  margin-bottom: 10px; }

.dms-preview-modal .dms-preview-footer-action {
  cursor: pointer; }

.dms-preview-modal .dms-preview-footer-action:not(:first-child) {
  margin-left: 20px; }

.dms-preview-modal .react-transform-component {
  overflow: unset !important; }

.dms-preview-modal .dms-preview-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, .8);
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .dms-preview-modal .dms-preview-footer h4, .dms-preview-modal .dms-preview-footer a {
    color: #fff; }
  .dms-preview-modal .dms-preview-footer h4 {
    margin-bottom: 5px; }
  .dms-preview-modal .dms-preview-footer a::-moz-selection {
    background-color: transparent; }
  .dms-preview-modal .dms-preview-footer a::selection {
    background-color: transparent; }

.dms-preview-footer-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 5px; }
  .dms-preview-footer-pagination button.dms-preview-footer-pagination--next, .dms-preview-footer-pagination button.dms-preview-footer-pagination--previous {
    background-color: transparent;
    border: 0;
    padding: 0;
    color: #F4F4F4; }
    .dms-preview-footer-pagination button.dms-preview-footer-pagination--next:disabled, .dms-preview-footer-pagination button.dms-preview-footer-pagination--previous:disabled {
      opacity: 0.5; }
  .dms-preview-footer-pagination span {
    color: #F4F4F4; }
