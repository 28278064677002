/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
/*=============================================>>>>>
= App Styles =
===============================================>>>>>*/
body {
  background: #000000;
  color: #353535; }

.wrapper {
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  max-width: 1400px; }
  @media (max-width: 768px) {
    .wrapper {
      padding: 0 10px !important; } }
  .wrapper.document-view-wrapper, .wrapper.document-list-wrapper, .wrapper.strata-data-logs-list-wrapper {
    max-width: 100%; }

.main-content {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #F4F4F4;
  overflow-y: auto; }
  .main-content.messages-content .container {
    height: calc(100vh - 70px); }

@media (min-width: 400px) {
  .mobile-down {
    display: none; } }

@media (min-width: 769px) {
  .tablet-down {
    display: none; } }

button {
  outline: none;
  cursor: pointer; }

.h-100 {
  height: 100%; }

.flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
