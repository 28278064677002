/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.row-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  white-space: nowrap;
  overflow: auto; }
  .row-items .row-items-static {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-column: 1 / 6;
    min-width: 500px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: #e7e7e7 1px solid; }
    .row-items .row-items-static .row-items-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-right: 5px; }
      @media (max-width: 768px) {
        .row-items .row-items-static .row-items-text {
          padding-right: 0; } }
      @media (max-width: 499px) {
        .row-items .row-items-static .row-items-text:nth-child(3) {
          width: 40px; } }
  @media (max-width: 768px) {
    .row-items {
      overflow-x: scroll;
      white-space: nowrap;
      text-overflow: scroll; } }
  .row-items .row-item0 {
    white-space: pre-line;
    min-width: 200px;
    overflow: hidden; }
    @media (max-width: 768px) {
      .row-items .row-item0 {
        width: 100px; } }
    @media (max-width: 659px) {
      .row-items .row-item0 {
        min-width: 120px; } }
  .row-items .row-item1 {
    width: 200px;
    white-space: pre-line;
    overflow: hidden; }
    @media (max-width: 768px) {
      .row-items .row-item1 {
        width: 150px; } }
    @media (max-width: 659px) {
      .row-items .row-item1 {
        width: 100px; } }
  .row-items .row-item2 {
    min-width: 75px; }
  .row-items .row-item3 {
    min-width: 75px; }
  .row-items .row-item4 {
    min-width: 91px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.item-button {
  border: none;
  background: none; }
  .item-button:before {
    width: 12px;
    height: 12px; }
  .item-button.add-item-button {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500; }
    .item-button.add-item-button:before {
      margin-right: 10px; }

.editing-fields-row-items {
  min-height: 55px; }

.row-items-fields-container {
  overflow: auto; }
  .row-items-fields-container .row-items-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px 10px; }
    .row-items-fields-container .row-items-container > .row-glcode, .row-items-fields-container .row-items-container > .row-glCodeValue {
      grid-column: 1 / span 3; }
    .row-items-fields-container .row-items-container > .row-description {
      grid-column: 4 / span 3; }
    .row-items-fields-container .row-items-container > .row-amount {
      grid-column: 1 / span 3; }
    .row-items-fields-container .row-items-container > .row-gst {
      grid-column: 4 / span 2; }
    .row-items-fields-container .row-items-container > .row-group, .row-items-fields-container .row-items-container > .row-groupCodeValue {
      grid-column: 6 / span 1; }
  .row-items-fields-container .remove-item-button {
    padding: 0; }

.accordion-wrapper + * {
  margin-top: 0.5em; }

.accordion-item {
  -webkit-transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px; }

.accordion-item.collapsed {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1); }

.accordion-title {
  background: #333333;
  padding-right: 16px;
  padding-right: 1rem;
  cursor: pointer;
  border: solid 1px #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .accordion-title::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff; }
  .accordion-title:hover, .accordion-title.open {
    color: #fff; }
  .accordion-title.open::after {
    content: '';
    border-top: 0;
    border-bottom: 5px solid; }
