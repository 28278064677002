/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.progress-bar-container {
  margin-top: 5px;
  height: 20px;
  position: relative;
  background: #555;
  border-radius: 25px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, .3);
          box-shadow: inset 0 -1px 1px rgba(255, 255, 255, .3); }

.progress-bar-container > .progress-bar-meter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 100%;
  border-radius: 8px;
  background-color: #4FCBB2;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(bottom, center), color-stop(37%, #4FCBB2), color-stop(69%, #54f054));
  background-image: linear-gradient(center bottom, #4FCBB2 37%, #54f054 69%);
  -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, .3), inset 0 -2px 6px rgba(0, 0, 0, .4);
          box-shadow: inset 0 2px 9px rgba(255, 255, 255, .3), inset 0 -2px 6px rgba(0, 0, 0, .4);
  position: relative;
  overflow: hidden; }

.progress-bar-container > .progress-bar-meter:after, .progress-bar-container > div > div {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  z-index: 1;
  background-size: 50px 50px;
  -webkit-animation: move 2s linear infinite;
          animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden; }

.progress-bar-container > .progress-bar-meter:after {
  display: none; }

.progress-bar-container > .progress-bar-meter:after, .progress-bar-container > div > div {
  -webkit-animation: move 2s linear infinite;
          animation: move 2s linear infinite; }

@-webkit-keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 50px 50px; } }

@keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 50px 50px; } }
