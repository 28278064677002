/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.search-field-container {
  position: relative; }
  .search-field-container .search-dropdown-container {
    position: absolute;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);
    z-index: 100;
    width: 100%; }
    .search-field-container .search-dropdown-container .dropdown-header {
      padding: 10px;
      background-color: #F4F4F4;
      text-transform: capitalize;
      font-weight: bold;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border-bottom: 1px solid #8C8C8C; }
      .search-field-container .search-dropdown-container .dropdown-header:before {
        margin-right: 10px; }
    .search-field-container .search-dropdown-container .search-dropdown-item {
      padding: 5px 10px;
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .search-field-container .search-dropdown-container .search-dropdown-item:hover {
        background-color: #4FCBB2;
        color: #ffffff; }
      .search-field-container .search-dropdown-container .search-dropdown-item .dropdown-label {
        display: inline-block;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .search-field-container .search-dropdown-container .loading-no-results {
      padding: 5px 10px; }
