/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.document-collapse-row {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column: 1 / span 24; }
  .document-collapse-row .row-item-first-empty-col {
    grid-column: 1 / span 3; }
  .document-collapse-row .accordion-item {
    width: 100%;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    grid-column: 4 / span 21; }
    .document-collapse-row .accordion-item hr {
      margin: 0.625em 0 1em 0; }
    .document-collapse-row .accordion-item .row {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(22, 1fr);
      font-size: 14px;
      font-weight: normal; }
    .document-collapse-row .accordion-item .row-item-first-col {
      grid-column: 1; }
    .document-collapse-row .accordion-item .row-item-second-col {
      grid-column: 2 / span 21; }
    .document-collapse-row .accordion-item .tag-row {
      margin-bottom: 1em; }
    .document-collapse-row .accordion-item .tag {
      margin-right: 4px;
      margin-bottom: 4px; }
    .document-collapse-row .accordion-item .note {
      width: 100%;
      white-space: normal;
      white-space: initial;
      display: inline-block; }
    .document-collapse-row .accordion-item .note-truncated {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; }
    .document-collapse-row .accordion-item .note-wrapper {
      position: relative; }
      .document-collapse-row .accordion-item .note-wrapper .show-more-link {
        position: absolute;
        bottom: 0px;
        right: 0px;
        background-color: white; }
      .document-collapse-row .accordion-item .note-wrapper .show-less-link {
        margin-left: 5px; }
