/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.react-select__control {
  border: 1px solid #E0E0E0 !important;
  -webkit-box-shadow: 0 1px 1px rgba(224, 224, 224, .3) !important;
          box-shadow: 0 1px 1px rgba(224, 224, 224, .3) !important;
  background: #ffffff !important;
  margin-bottom: 0; }
  .react-select__control.react-select__control--is-focused {
    border-color: #4FCBB2 !important;
    outline: none; }
  .react-select__control .react-select__input input {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    height: 30px !important;
    line-height: 1.5em !important;
    margin: 0 !important; }

.react-select__option {
  padding: 3px 10px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: #EEE 1px solid;
  cursor: pointer;
  font-size: 14px !important;
  line-height: 1.4em !important; }
  .react-select__option.disabled {
    background-color: #EAEDF0; }
  .react-select__option.react-select__option--is-focused, .react-select__option.react-select__option--is-selected {
    background: #F4F4F4;
    color: #353535; }
  .react-select__option:active {
    background-color: #F4F4F4 !important; }
  .react-select__option:last-child {
    border-bottom: none; }
  .react-select__option .avatar {
    margin: 0 8px 0 0; }

.react-select-avatar-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px; }

.react-select-approved {
  width: 20px;
  height: 20px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iY2hlY2tib3gtLy1jaGVja2VkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMy4wMDAwMDAsIC01LjAwMDAwMCkiIGZpbGw9IiMzMzMzMzMiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSIjMzMzMzMzIj4KICAgICAgICAgICAgPHBhdGggZD0iTTExLDUuNzA3MTA2NzggTDYuNzE4NzUsOS45ODgzNTY3OCBMNC40Mzc1LDcuNzA3MTA2NzggTDMuNzA3MDk2NDcsOC40Mzc1MTAzMSBMNi4zNDU2MTEzMiwxMS4wNzYxNzkxIEw2LjcxODc5MzQxLDExLjQzMzEzNTkgTDcuMDgzOTQ2NjEsMTEuMDgzOTQ2NiBMMTEuNzMwMzkzMiw2LjQzNzUgTDExLDUuNzA3MTA2NzggWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==) no-repeat center; }

.react-select-user-role {
  margin-left: auto;
  text-transform: capitalize;
  font-size: 14px; }

.react-select__menu {
  z-index: 99999999 !important;
  min-width: 200px; }

.react-select__menu-list {
  padding: 0 !important; }

.react-select__multi-value {
  background: #F4F4F4 !important; }

.react-select-user-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .react-select-user-label .avatar {
    margin: 0 5px 0 3px; }

.react-select--is-disabled .react-select__control--is-disabled {
  background-color: #F2F2F2 !important; }
