/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.accordion-wrapper {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
          box-shadow: 0 0 10px rgba(0, 0, 0, .1); }
  .accordion-wrapper + * {
    margin-top: 0.5em; }

.accordion-item {
  -webkit-transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px; }

.accordion-item.collapsed {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1); }

.accordion-title {
  background: #333333;
  padding-right: 16px;
  padding-right: 1rem;
  cursor: pointer;
  border: solid 1px #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .accordion-title::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff; }
  .accordion-title:hover, .accordion-title.open {
    color: #fff; }
  .accordion-title.open::after {
    content: '';
    border-top: 0;
    border-bottom: 5px solid; }
