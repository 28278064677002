/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dropdown-wrapper {
  position: relative;
  outline: none; }
  .dropdown-wrapper .label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    margin: 0;
    padding: 0 12px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    height: 32px;
    font-size: 14px;
    line-height: 1em;
    border-radius: 8px;
    border: #F4F4F4 1px solid;
    background: white;
    color: #353535;
    cursor: pointer;
    z-index: 1; }
    @media (max-width: 399px) {
      .dropdown-wrapper .label {
        height: 24px;
        line-height: 24px;
        border-radius: 2px; } }
    @media (max-width: 339px) {
      .dropdown-wrapper .label {
        padding: 2px 4px;
        font-size: 14px; } }
    .dropdown-wrapper .label:before {
      display: inline-block;
      margin: 0 0 0 10px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
      color: rgba(53, 53, 53, .6);
      width: 10px;
      height: 10px; }
    .dropdown-wrapper .label .badge {
      margin-left: 8px;
      background: #333333; }
      @media (max-width: 339px) {
        .dropdown-wrapper .label .badge {
          margin-left: 4px;
          height: 18px;
          width: 18px;
          line-height: 18px;
          font-size: 12px; } }
    .dropdown-wrapper .label.active {
      border-radius: 4px; }
      .dropdown-wrapper .label.active:before {
        -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg);
        color: #ffffff; }
      .dropdown-wrapper .label.active + .dropdown {
        top: 40px;
        height: auto;
        opacity: 1;
        visibility: visible;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; }
    .dropdown-wrapper .label.disabled {
      background: #EAEDF0;
      cursor: not-allowed; }
    .dropdown-wrapper .label:hover {
      text-decoration: none; }

.dropdown {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 999;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  -webkit-box-shadow: 0 14px 36px 2px rgba(0, 0, 0, .15);
          box-shadow: 0 14px 36px 2px rgba(0, 0, 0, .15);
  padding: 10px 5px;
  width: 300px;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-transition: top 0.4s, opacity 0.2s;
  transition: top 0.4s, opacity 0.2s; }
  @media (max-width: 399px) {
    .dropdown {
      margin: 0 10px;
      top: 30px;
      width: calc(100% - 20px);
      max-width: 300px;
      min-width: 200px; } }
  .dropdown.alignright {
    left: auto;
    right: 0; }
  .dropdown .dropdown-item a {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 20px;
    height: 35px;
    width: 100%;
    text-decoration: none;
    cursor: pointer; }
    .dropdown .dropdown-item a:hover {
      text-decoration: underline; }
  .dropdown .dropdown-item--danger {
    color: #DC3545; }
    .dropdown .dropdown-item--danger a {
      color: #DC3545; }
