/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.banner {
  padding: 8px;
  margin-bottom: 10px; }
  .banner .banner-content {
    max-width: 100%;
    margin: 0px auto;
    padding: 0px 40px;
    text-align: center;
    position: relative;
    text-align: center; }
    @media (max-width: 1024px) {
      .banner .banner-content {
        padding: 0px 25px; } }
    .banner .banner-content b {
      font-weight: bold; }
    .banner .banner-content i {
      font-style: italic; }
    .banner .banner-content a {
      text-decoration: underline;
      color: inherit; }
    .banner .banner-content .dismiss-button {
      background-color: transparent;
      border: none;
      position: absolute;
      right: 50px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      font-size: 16px; }
      @media (max-width: 1024px) {
        .banner .banner-content .dismiss-button {
          right: 10px; } }
