/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.create-noticeboard-modal .notice-text {
  max-width: 452px; }
  @media (max-width: 1024px) {
    .create-noticeboard-modal .notice-text {
      max-width: 100%; } }

.create-noticeboard-modal .horizontal-separator {
  position: relative;
  text-align: center;
  height: 20px; }
  .create-noticeboard-modal .horizontal-separator hr {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    margin: 0px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .create-noticeboard-modal .horizontal-separator span {
    display: inline-block;
    background-color: #fff;
    padding: 0px 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
