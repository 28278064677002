/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.pass-to-user-container {
  min-height: 460px;
  padding: 0 15px 15px 15px; }
  .pass-to-user-container .pass-to-user-title {
    margin-bottom: 30px; }
  .pass-to-user-container .pass-to-user-form {
    min-height: 380px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .pass-to-user-container .pass-to-user-form .pass-user-fields-container .fields .field .exclude-users-field {
      margin-bottom: 30px; }
    .pass-to-user-container .pass-to-user-form .pass-user-fields-container .note-field {
      margin: 20px 0; }
    .pass-to-user-container .pass-to-user-form .pass-user-fields-container .warning-message-container {
      margin-bottom: 30px; }
      .pass-to-user-container .pass-to-user-form .pass-user-fields-container .warning-message-container .exclude-warning-text.active {
        color: #D4504B; }
      .pass-to-user-container .pass-to-user-form .pass-user-fields-container .warning-message-container .exclude-warning-text.inactive {
        display: none; }
    .pass-to-user-container .pass-to-user-form .pass-to-user-button {
      width: 100%;
      margin-bottom: 10px;
      background-color: #4FCBB2;
      border: #4FCBB2; }
      .pass-to-user-container .pass-to-user-form .pass-to-user-button:hover {
        background-color: #000000;
        color: #ffffff; }
    .pass-to-user-container .pass-to-user-form .pass-to-user-button-next-invoice {
      width: 100%;
      background-color: #3bc5a9;
      border: #3bc5a9; }
      .pass-to-user-container .pass-to-user-form .pass-to-user-button-next-invoice:hover {
        background-color: #000000;
        color: #ffffff; }
    .pass-to-user-container .pass-to-user-form .override-approve-button {
      margin-top: 20px;
      width: 100%;
      background-color: #D4504B;
      border: #D4504B; }
      .pass-to-user-container .pass-to-user-form .override-approve-button:hover {
        background-color: #000000;
        color: #ffffff; }

.override-container {
  min-height: 200px;
  padding: 0 15px 15px 15px; }
  .override-container .override-title {
    margin-bottom: 20px; }
  .override-container .override-approve-button {
    margin-top: 20px;
    width: 100%;
    background-color: #D4504B;
    border: #D4504B; }
    .override-container .override-approve-button:hover {
      background-color: #aa2d29; }
