/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.action-form .buttons-container {
  width: 100%;
  margin: 20px 0 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.action-form .close-button {
  width: 49%;
  border: 1px solid #333333;
  background: #ffffff;
  color: #333333; }
  .action-form .close-button:hover {
    background-color: #000000;
    color: #ffffff; }

.action-form .success-button {
  width: 49%; }
  .action-form .success-button.green {
    background-color: #4FCBB2;
    border: 1px solid #4FCBB2; }
    .action-form .success-button.green:hover {
      background-color: #000000;
      color: #ffffff; }
  .action-form .success-button.red {
    background-color: #D4504B;
    border: 1px solid #D4504B; }
    .action-form .success-button.red:hover {
      background-color: #aa2d29; }
