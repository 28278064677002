/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.file-type-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 31px;
  height: 40px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzFweCIgaGVpZ2h0PSI0MHB4IiB2aWV3Qm94PSIwIDAgMzEgNDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5maWxlLWJsdWU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkZpbGVzL0F0dGFjaG1lbnRzL1NtYWxsL0RPQyI+CiAgICAgICAgICAgIDxnIGlkPSJmaWxlLWJsdWUiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTIuNSwwIEwxOS43NDEyLDAgTDMwLjk5OTksMTEuMjE1IEwzMC45OTk5LDM3LjUgQzMwLjk5OTksMzguODgxMiAyOS44OCw0MCAyOC40OTk5LDQwIEwyLjUsNDAgQzEuMTE5OTYsNDAgMi4wNzQyNGUtMDUsMzguODgxMiAyLjA3NDI0ZS0wNSwzNy41IEwyLjA3NDI0ZS0wNSwyLjQ5OTk4IEMyLjA3NDI0ZS0wNSwxLjExODc3IDEuMTIwMDksMCAyLjUsMCBaIiBpZD0iUGF0aCIgZmlsbD0iIzI2QTZEMSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTMwLjk2MzgsMTEuMjUgTDIyLjI1LDExLjI1IEMyMC44Njk5LDExLjI1IDE5Ljc1LDEwLjEzMDEgMTkuNzUsOC43NTAwNCBMMTkuNzUsMC4wMjUwMjQ0IEwzMC45NjM4LDExLjI1IFoiIGlkPSJQYXRoIiBmaWxsPSIjMUU3RUEwIj48L3BhdGg+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==) no-repeat center;
  background-size: contain;
  color: #ffffff;
  font-size: 10px;
  text-transform: uppercase; }
  .file-type-icon.large {
    width: 55px;
    height: 70px;
    font-size: 13px; }
  .file-type-icon.xlarge {
    width: 79px;
    height: 102px;
    font-size: 18px; }
  .file-type-icon.small {
    width: 20px;
    height: 25px;
    font-size: 7px; }
  .file-type-icon.file-blue {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzFweCIgaGVpZ2h0PSI0MHB4IiB2aWV3Qm94PSIwIDAgMzEgNDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5maWxlLWJsdWU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkZpbGVzL0F0dGFjaG1lbnRzL1NtYWxsL0RPQyI+CiAgICAgICAgICAgIDxnIGlkPSJmaWxlLWJsdWUiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTIuNSwwIEwxOS43NDEyLDAgTDMwLjk5OTksMTEuMjE1IEwzMC45OTk5LDM3LjUgQzMwLjk5OTksMzguODgxMiAyOS44OCw0MCAyOC40OTk5LDQwIEwyLjUsNDAgQzEuMTE5OTYsNDAgMi4wNzQyNGUtMDUsMzguODgxMiAyLjA3NDI0ZS0wNSwzNy41IEwyLjA3NDI0ZS0wNSwyLjQ5OTk4IEMyLjA3NDI0ZS0wNSwxLjExODc3IDEuMTIwMDksMCAyLjUsMCBaIiBpZD0iUGF0aCIgZmlsbD0iIzI2QTZEMSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTMwLjk2MzgsMTEuMjUgTDIyLjI1LDExLjI1IEMyMC44Njk5LDExLjI1IDE5Ljc1LDEwLjEzMDEgMTkuNzUsOC43NTAwNCBMMTkuNzUsMC4wMjUwMjQ0IEwzMC45NjM4LDExLjI1IFoiIGlkPSJQYXRoIiBmaWxsPSIjMUU3RUEwIj48L3BhdGg+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==); }
  .file-type-icon.file-green {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzFweCIgaGVpZ2h0PSI0MHB4IiB2aWV3Qm94PSIwIDAgMzEgNDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5maWxlLWdyZWVuPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJGaWxlcy9BdHRhY2htZW50cy9TbWFsbC9ET0MiPgogICAgICAgICAgICA8ZyBpZD0iZmlsZS1ncmVlbiI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMi41LDAgTDE5Ljc0MTIsMCBMMzAuOTk5OSwxMS4yMTUgTDMwLjk5OTksMzcuNSBDMzAuOTk5OSwzOC44ODEyIDI5Ljg4LDQwIDI4LjQ5OTksNDAgTDIuNSw0MCBDMS4xMTk5Niw0MCAyLjA3NDI0ZS0wNSwzOC44ODEyIDIuMDc0MjRlLTA1LDM3LjUgTDIuMDc0MjRlLTA1LDIuNDk5OTggQzIuMDc0MjRlLTA1LDEuMTE4NzcgMS4xMjAwOSwwIDIuNSwwIFoiIGlkPSJQYXRoIiBmaWxsPSIjMDlCNjZEIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMzAuOTYzOCwxMS4yNSBMMjIuMjUsMTEuMjUgQzIwLjg2OTksMTEuMjUgMTkuNzUsMTAuMTMwMSAxOS43NSw4Ljc1MDA0IEwxOS43NSwwLjAyNTAyNDQgTDMwLjk2MzgsMTEuMjUgWiIgaWQ9IlBhdGgiIGZpbGw9IiMwMEEzNDQiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+); }
  .file-type-icon.file-red {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzFweCIgaGVpZ2h0PSI0MHB4IiB2aWV3Qm94PSIwIDAgMzEgNDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5maWxlLXJlZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iRmlsZXMvQXR0YWNobWVudHMvU21hbGwvRE9DIj4KICAgICAgICAgICAgPGcgaWQ9ImZpbGUtcmVkIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yLjUsMCBMMTkuNzQxMiwwIEwzMC45OTk5LDExLjIxNSBMMzAuOTk5OSwzNy41IEMzMC45OTk5LDM4Ljg4MTIgMjkuODgsNDAgMjguNDk5OSw0MCBMMi41LDQwIEMxLjExOTk2LDQwIDIuMDc0MjRlLTA1LDM4Ljg4MTIgMi4wNzQyNGUtMDUsMzcuNSBMMi4wNzQyNGUtMDUsMi40OTk5OCBDMi4wNzQyNGUtMDUsMS4xMTg3NyAxLjEyMDA5LDAgMi41LDAgWiIgaWQ9IlBhdGgiIGZpbGw9IiNENDUwNEIiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0zMC45NjM4LDExLjI1IEwyMi4yNSwxMS4yNSBDMjAuODY5OSwxMS4yNSAxOS43NSwxMC4xMzAxIDE5Ljc1LDguNzUwMDQgTDE5Ljc1LDAuMDI1MDI0NCBMMzAuOTYzOCwxMS4yNSBaIiBpZD0iUGF0aCIgZmlsbD0iI0I1MzYyOSI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=); }
  .file-type-icon.file-yellow {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzFweCIgaGVpZ2h0PSI0MHB4IiB2aWV3Qm94PSIwIDAgMzEgNDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5maWxlLXllbGxvdzwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iRmlsZXMvQXR0YWNobWVudHMvU21hbGwvRE9DIj4KICAgICAgICAgICAgPGcgaWQ9ImZpbGUteWVsbG93Ij4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yLjUsMCBMMTkuNzQxMiwwIEwzMC45OTk5LDExLjIxNSBMMzAuOTk5OSwzNy41IEMzMC45OTk5LDM4Ljg4MTIgMjkuODgsNDAgMjguNDk5OSw0MCBMMi41LDQwIEMxLjExOTk2LDQwIDIuMDc0MjRlLTA1LDM4Ljg4MTIgMi4wNzQyNGUtMDUsMzcuNSBMMi4wNzQyNGUtMDUsMi40OTk5OCBDMi4wNzQyNGUtMDUsMS4xMTg3NyAxLjEyMDA5LDAgMi41LDAgWiIgaWQ9IlBhdGgiIGZpbGw9IiNGNEI0NTkiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0zMC45NjM4LDExLjI1IEwyMi4yNSwxMS4yNSBDMjAuODY5OSwxMS4yNSAxOS43NSwxMC4xMzAxIDE5Ljc1LDguNzUwMDQgTDE5Ljc1LDAuMDI1MDI0NCBMMzAuOTYzOCwxMS4yNSBaIiBpZD0iUGF0aCIgZmlsbD0iI0QwN0M0MCI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=); }
