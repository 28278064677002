/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.crystal-report-item {
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #F4F4F4;
  border: #F4F4F4 1px solid;
  padding: 10px 20px;
  margin: 0 0 10px; }

.crystal-report-item-col {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .crystal-report-item-col.details {
    grid-column: 1 / span 7;
    display: grid; }
    .crystal-report-item-col.details .created-date {
      grid-row: 1 / span 1; }
    .crystal-report-item-col.details .selected-date {
      grid-row: 2 / span 1; }
    .crystal-report-item-col.details .sp-numbers {
      grid-row: 3 / span 1; }
    .crystal-report-item-col.details .report-types {
      grid-row: 4 / span 1; }
  .crystal-report-item-col.download-btn {
    grid-column: 8 / span 1;
    justify-self: end; }
  .crystal-report-item-col.status-in-progress {
    color: #78E0CB; }
  .crystal-report-item-col.status-error {
    color: #D4504B; }
  .crystal-report-item-col.status-completed {
    color: #0bc50b; }
