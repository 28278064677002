/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.notification-dropdown-container {
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .notification-dropdown-container .notification-indicator-wrapper {
    position: relative; }
    .notification-dropdown-container .notification-indicator-wrapper .notification-indicator {
      position: absolute;
      top: -5px;
      right: -3px;
      background: #4FCBB2;
      width: 8px;
      height: 8px;
      border-radius: 4px; }
  .notification-dropdown-container .icon-text {
    display: none;
    font-weight: normal; }
  @media (max-width: 768px) {
    .notification-dropdown-container {
      padding: 10px 0; } }
  .notification-dropdown-container .notification-header-dropdown-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: white;
    font-weight: bold;
    font-size: 14px; }
    @media (max-width: 1024px) {
      .notification-dropdown-container .notification-header-dropdown-container {
        margin-right: 20px; }
        .notification-dropdown-container .notification-header-dropdown-container .icon-text {
          display: block; } }
    @media (max-width: 768px) {
      .notification-dropdown-container .notification-header-dropdown-container {
        margin-right: 0; } }
    .notification-dropdown-container .notification-header-dropdown-container .notification-dropdown-unread-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      cursor: pointer;
      position: relative; }
      .notification-dropdown-container .notification-header-dropdown-container .notification-dropdown-unread-container:before {
        height: 26px;
        width: 26px; }
      @media (max-width: 1024px) {
        .notification-dropdown-container .notification-header-dropdown-container .notification-dropdown-unread-container .notification-dropdown-unread-text {
          display: none; } }
  .notification-dropdown-container .notification-title {
    font-size: 0; }
    @media (max-width: 768px) {
      .notification-dropdown-container .notification-title {
        font-size: 13px;
        color: #ffffff;
        letter-spacing: 1px; } }
  .notification-dropdown-container .notification-dropdown-list {
    position: absolute;
    background: #000000;
    top: 49px;
    right: 0;
    color: #ffffff;
    width: 400px;
    overflow-y: auto;
    cursor: default;
    font-size: 13px;
    letter-spacing: 1px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 6px 8px 0 rgba(0, 0, 0, .1); }
    .notification-dropdown-container .notification-dropdown-list .loading {
      height: 500px; }
    .notification-dropdown-container .notification-dropdown-list .dropdown-divider {
      height: 0;
      margin: 8px 0;
      margin: 0.5rem 0;
      overflow: hidden;
      border-top: 1px solid #e9ecef; }
    .notification-dropdown-container .notification-dropdown-list .notification-item-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .notification-dropdown-container .notification-dropdown-list .notification-item-header .noti-title {
        position: relative;
        font-weight: bold; }
      .notification-dropdown-container .notification-dropdown-list .notification-item-header .icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-right: 6px;
        margin-right: 0.375rem;
        text-align: center; }
        .notification-dropdown-container .notification-dropdown-list .notification-item-header .icon::before {
          width: 15px;
          height: 15px;
          -webkit-mask-position: 25% 50%;
                  mask-position: 25% 50%; }
      .notification-dropdown-container .notification-dropdown-list .notification-item-header .noti-ref-hash {
        margin-left: auto; }
    .notification-dropdown-container .notification-dropdown-list .notification-item-body {
      padding: 4px 0;
      padding: 0.25rem 0;
      line-height: 1.25; }
    .notification-dropdown-container .notification-dropdown-list .notification-item-footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 85%;
      color: #aaa;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .notification-dropdown-container .notification-dropdown-list .all-notifications-link {
      font-weight: bold;
      font-size: 12px;
      padding-bottom: 2px;
      letter-spacing: 0.5px; }
    .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item {
      display: block;
      position: relative;
      padding: 12px 36px 12px 20px;
      padding: 0.75rem 36px 0.75rem 20px;
      line-height: 1.5;
      text-decoration: none; }
      .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item.mark-all-read {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; }
      .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item + .notification-dropdown-list-item {
        border-top: 1px solid #e9ecef; }
      .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item .notification-dropdown-item-title-container {
        display: grid;
        grid-template-columns: 10% auto;
        cursor: pointer; }
        .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item .notification-dropdown-item-title-container .dropdown-icon {
          margin-top: 5px;
          width: 14px;
          height: 14px;
          border-radius: 50%; }
          .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item .notification-dropdown-item-title-container .dropdown-icon.read {
            background-color: #E8E8E8; }
          .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item .notification-dropdown-item-title-container .dropdown-icon.unread {
            background-color: #4FCBB2; }
        .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item .notification-dropdown-item-title-container .dropdown-list-item-title {
          font-size: 14px;
          line-height: 1.4em;
          grid-column: 2 / 3; }
        .notification-dropdown-container .notification-dropdown-list .notification-dropdown-list-item .notification-dropdown-item-title-container .dropdown-item-time {
          font-size: 13px;
          color: #8C8C8C;
          grid-column: 2 / 3; }

.notification-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 12px 20px;
  padding: 0.75rem 20px;
  font-weight: 900;
  font-size: 12px;
  font-size: 0.75rem;
  border: 1px solid #e9ecef;
  border-width: 1px 0; }
  .notification-section:first-child {
    border-top-width: 0; }
  .notification-section:last-child {
    border-bottom-width: 0; }
  .notification-section b {
    margin-right: 4px;
    margin-right: 0.25rem;
    text-transform: uppercase; }
  .notification-section a {
    margin: 0;
    padding: 0; }
  .notification-section .notification-mark-all {
    display: block;
    position: absolute;
    top: 50%;
    right: 8px;
    right: 0.5rem;
    margin-top: -8px;
    margin-top: -0.5rem; }

.notification-toggle-read {
  position: absolute;
  top: 50%;
  right: 8px;
  right: 0.5rem;
  margin-top: -7px;
  margin-top: -0.4375rem; }
  .notification-toggle-read span {
    display: block;
    padding: 4px;
    padding: 0.25rem; }
    .notification-toggle-read span::before {
      display: block;
      width: 10px;
      width: 0.625rem;
      height: 10px;
      height: 0.625rem;
      background-color: #4FCBB2;
      border-radius: 50%;
      content: ""; }
  .notification-toggle-read.read {
    opacity: 0;
    -webkit-transition: opacity .2s;
    transition: opacity .2s; }
    .notification-toggle-read.read span::before {
      background-color: white;
      border: 1px solid #4FCBB2; }

.notification-dropdown-list-item:hover .notification-toggle-read.read {
  opacity: 1; }

.theme-dark .notification-section {
  color: #ffffff; }

.theme-dark a.notification-dropdown-list-item:hover {
  background-color: rgba(255, 255, 255, .1); }

.theme-light .notification-section {
  color: #000000; }

.theme-light a.notification-dropdown-list-item:hover {
  background-color: rgba(0, 0, 0, .1); }
