/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.recurring-rule-card {
  border: 1.5px solid #DFDFDF;
  margin: 15px 0;
  padding: 15px;
  position: relative;
  border-radius: 10px; }
  .recurring-rule-card:hover {
    background-color: #F8F8F8; }
  .recurring-rule-card .recurring-rule-actions {
    position: absolute;
    top: 5px;
    right: 0;
    margin: 0;
    padding: 0;
    opacity: 1; }
    .recurring-rule-card .recurring-rule-actions button {
      border: none;
      border-radius: 10px;
      background-color: #F8F8F8; }
  .recurring-rule-card .recurring-rule-range {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .recurring-rule-card p {
    margin-bottom: 15px; }
  .recurring-rule-card hr {
    border: 1.5px solid #DFDFDF;
    border-radius: 0.75px;
    margin: 10px 0 15px 0; }

button.add-rule-btn {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0;
  background: #4FCBB2;
  width: 47.5px;
  height: 47.5px;
  border-radius: 50%;
  border: none;
  float: right;
  margin-bottom: 15px; }

.search-input {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  max-width: 85%; }

.recurring-rules-footer-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .recurring-rules-footer-button-container .button.secondary.close-btn {
    background: #ffffff;
    color: #333333;
    border: 1px solid #333333; }
    .recurring-rules-footer-button-container .button.secondary.close-btn:hover {
      background: #333333;
      color: #ffffff;
      border: 1px solid #333333; }
  .recurring-rules-footer-button-container .close-btn, .recurring-rules-footer-button-container .save-btn {
    padding: 10px 60px;
    font-weight: bold;
    font-size: 14px; }

.new-recurring-rule, .edit-recurring-rule {
  margin-bottom: 30px; }

.react-tabs__tab-panel .loading {
  height: 20vh; }
