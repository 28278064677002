/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.document-list-items .dms-pdf-gridview .react-pdf__Page {
  width: 100% !important;
  height: 150px !important;
  overflow: hidden !important; }
  .document-list-items .dms-pdf-gridview .react-pdf__Page .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important; }

.document-list-items.dms-grid-view {
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 20px 0; }
  @media (max-width: 1024px) {
    .document-list-items.dms-grid-view {
      grid-template-columns: repeat(3, 1fr); } }
  @media (max-width: 659px) {
    .document-list-items.dms-grid-view {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 499px) {
    .document-list-items.dms-grid-view {
      grid-template-columns: repeat(1, 1fr); } }
