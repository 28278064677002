/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
/*=============================================>>>>>
= Modal Helper =
===============================================>>>>>*/
.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, .4);
  padding: 5% 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 339px) {
    .modal {
      padding: 5% 15px; } }
  .modal .modal-content {
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: calc(100% - 40px);
    max-width: 700px;
    max-height: 90%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: #ffffff;
    border-radius: 3px;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
            box-shadow: 0 5px 15px rgba(0, 0, 0, .5); }
    @media (min-width: 2200px) {
      .modal .modal-content {
        width: 600px; } }
    @media (max-width: 339px) {
      .modal .modal-content {
        width: calc(100% - 30px); } }
    .modal .modal-content.custom-title {
      padding: 0; }
    .modal .modal-content .modal-title-close.title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      background-color: #000000;
      padding: 15px 30px; }
    .modal .modal-content .modal-title-close .modal-title {
      color: #ffffff; }
    .modal .modal-content .modal-title-close a {
      width: 30px;
      -ms-flex-item-align: end;
          align-self: flex-end; }
    .modal .modal-content .modal-title-close .modal-cross-close {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; }
      .modal .modal-content .modal-title-close .modal-cross-close.custom-title:before {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: #000000;
        border-color: #000000; }
      .modal .modal-content .modal-title-close .modal-cross-close:before {
        height: 18px;
        width: 18px;
        background-color: #EAEDF0;
        border: 6px solid #EAEDF0;
        border-radius: 50%; }
  .modal.full-screen .modal-content {
    width: 95vw;
    height: 95vh;
    max-height: 95vh; }
  .modal.iframe-modal .modal-content .iframe-container {
    height: 100%;
    width: 100%;
    position: relative; }
  .modal.iframe-modal .modal-content .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .modal.iframe-modal .modal-content iframe {
    position: relative;
    z-index: 9; }
  .modal .modal-content-title {
    background: #4FCBB2;
    color: #ffffff;
    padding: 0 20px;
    min-height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: #F4F4F4 1px solid; }
    .modal .modal-content-title h3 {
      font-size: 18px; }
    .modal .modal-content-title .close:hover {
      text-decoration: none; }
  .modal .modal-content-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    background: #F4F4F4;
    padding: 20px; }
    .modal .modal-content-body .fields .field-heading:first-child {
      border-top: 0; }
    .modal .modal-content-body .fields .react-select-field {
      padding-bottom: 10px; }
    .modal .modal-content-body .fields .checkboxes-field {
      padding-bottom: 15px; }
    .modal .modal-content-body .button {
      width: 100%;
      padding: 12px 20px;
      font-size: 15px; }
  .modal .modal-content-list {
    padding: 10px 0 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .modal .modal-content-list-item {
    padding: 10px 15px;
    width: 100%; }
    .modal .modal-content-list-item.timestamp {
      border-top: #F4F4F4 1px solid;
      padding-top: 20px;
      margin: 10px 0 0; }
    .modal .modal-content-list-item small {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      font-size: 11px;
      color: #c1c1c1; }
    .modal .modal-content-list-item.split {
      width: 50%; }
  .modal .modal-content-list-item-title {
    color: #a8a8a8;
    margin: 0 0 4px;
    min-width: 100%; }
  .modal .modal-content-list-item-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 15px; }
    .modal .modal-content-list-item-content input, .modal .modal-content-list-item-content .fill-row {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      line-height: 1em;
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .modal .modal-content-list-item-content .tags-list {
      padding-top: 2px; }
      .modal .modal-content-list-item-content .tags-list .tag {
        margin: 4px 8px 0 0; }
  .modal .model-content-list-item-sublist a {
    width: 100%;
    padding: 5px 0;
    display: block; }
  .modal .modal-footer-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .modal .modal-footer-actions .button {
      width: auto;
      min-width: 110px; }
  .modal .loader {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 50px 0; }
