/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.sharing-modal-container {
  padding: 30px; }
  .sharing-modal-container .sharing-modal .checkboxes-field.inactive .options .option label:before {
    background-color: #F2F2F2; }
  .sharing-modal-container .sharing-modal .everyone-checkbox, .sharing-modal-container .sharing-modal .tenant-checkbox {
    grid-column: 1 / 2; }
  .sharing-modal-container .button-container {
    margin-top: 19px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .sharing-modal-container .react-select__menu {
    z-index: 1001;
    max-height: 145px; }
    .sharing-modal-container .react-select__menu .react-select__menu-list {
      max-height: 140px; }
