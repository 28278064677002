/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.bulk-approve-invoice-modal-body table#table-bulk-approve-invoice tbody > tr.error > td, .bulk-approve-invoice-modal-body table#table-bulk-approve-invoice tbody > tr.duplicate > td {
  border-bottom: none;
  padding-bottom: 0; }

.bulk-approve-invoice-modal-body table#table-bulk-approve-invoice tbody > tr.duplicate-label > td {
  border-top: none;
  padding-top: 0; }

.bulk-approve-invoice-modal-body .invoice-status {
  text-transform: uppercase; }

.bulk-approve-invoice-modal-body .buttons-container {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.bulk-approve-invoice-modal-body th.date-col {
  min-width: 90px; }

.bulk-approve-invoice-modal-body th.invoice-price-col {
  min-width: 80px; }
